import React, { Dispatch, FC, useState } from 'react'
import '../../styles/firstlevel.css'
import MarketShare from './components/MarketShare.tsx'
import ProspectingProb from './components/ProspectingProb.tsx'
import ProspectingbyStatus from './components/ProspectingbyStatus.tsx'
import SalesLeaders from './components/SalesLeaders.tsx'
import Wallet from './components/Wallet.tsx'
import Operations from './components/Operations.tsx'
import FiltersSection from './components/Filters.tsx'
import BudgetAndSold from './components/BudgetAndSold.tsx'
import { Filters } from '../../types.ts'

type Props = {
     filters: Filters;
     setFilters: Dispatch<React.SetStateAction<Filters>>
}

const Summary: FC<Props> = ({ filters, setFilters }) => {

     const [toggleProspecting, setToggleProspecting] = useState<boolean | null>(null)

     return (
          <section className='grid-container'>
               <FiltersSection 
                    mainClassName='box filters' 
                    filters={filters}
                    setFilters={setFilters} 
               />

               <BudgetAndSold 
                    mainClassName='box chart-presupuesto' 
                    filters={filters}
               />

               <MarketShare 
                    mainClassName='box chart-market-share'
                    filters={filters} 
               />

               <ProspectingProb 
                    mainClassName='box prospeccion-probabilidad' 
                    filters={filters} 
                    setToggleProspecting={setToggleProspecting}
                    toggleProspecting={toggleProspecting}
               />

               <ProspectingbyStatus 
                    mainClassName='box prospeccion-status' 
                    filters={filters} 
                    setToggleProspecting={setToggleProspecting}
                    toggleProspecting={toggleProspecting}
               />

               <SalesLeaders 
                    mainClassName='box visitas' 
                    filters={filters}
               />
               

               <Operations 
                    mainClassName='box operaciones' 
               />

               <Wallet 
                    mainClassName='box cartera' 
               />
          </section>
     )
}

export default Summary