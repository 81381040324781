import React, { useEffect, useState } from 'react';
import { Dropdown } from 'primereact';
import { GetEmployees, GetEmployeesSingle } from '../request/index';

interface DropdownEmployeeProps {
    branch: number;
    departments: number[] | null;
    onValueChange: (value: any) => void;
    withAll ?: boolean
}

const DropdownEmployeeSingle: React.FC<DropdownEmployeeProps> = ({ branch, departments, onValueChange, withAll }) => {

    const [selectedEmployee, setSelectedEmployee] = useState<any>(null);
    const [employeesList, setEmployeesList] = useState<any>(null);

    useEffect(() => {
        const initialize = async () => {
            const employeesFetched = await GetEmployeesSingle(branch, departments);
            setEmployeesList(employeesFetched);
            // setSelectedEmployee(null);
            if(branch == -1){

            }
        };
        initialize();
    }, [branch, departments]);

    useEffect(()=>{
        if(selectedEmployee === null){
            onValueChange(selectedEmployee);
        }
        else {
            onValueChange(selectedEmployee.employeeId);
        }
    }, [selectedEmployee])
    
    const employeeOptions = employeesList?.map((employee: any) => ({
        label: employee.name,
        value: employee
    })) || [];

    return (
        <span className="p-float-label panel-element">
            <Dropdown
                id="dd-employees"
                value={selectedEmployee}
                options={
                    withAll ? [{ label: 'Todos los Colaboradores', employeeId: -1 },...employeeOptions] : [...employeeOptions]
                }
                onChange={(e:any) => {
                    setSelectedEmployee(e.value);
                }}
                placeholder="Colaborador"
                className="dropdown"
                filter
            />
            <label htmlFor="dd-employees">Colaborador</label>
        </span>
    );
};

export default DropdownEmployeeSingle;
