import { Button } from 'primereact';
import axios from 'axios';

export const TableHeader = (textHeader, tableData, tableSource) => {

    const download = async () => {
        try {
            const response = await axios.post('/backend/budgetadvancement/generateExcel', tableData, {
                headers: {
                    'Content-Type': 'application/json',
                },
                responseType: 'blob',
            });
            const file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            
            const link = document.createElement('a');
            link.href = URL.createObjectURL(file);
            link.download = `${textHeader}.xlsx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (error) {
            console.error('Error al generar el archivo:', error);
        }
    };

    return (
        <div className="d-flex flex-row align-items-center justify-content-end gap-8">
            <span className="pt-1 mr-auto ml-1 p-panel p-panel-header p-panel-title ">{textHeader}</span>
            
            <Button
                label="Exportar a Excel"
                icon="pi pi-file-excel"
                iconPos="right"
                severity="success"
                onClick={download}  
                size="small"
                rounded
            />
        </div>
    );
};

