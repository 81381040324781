import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";

type Props = {
     onValueChange: (param: string)=>void
 }
 
const TextInput: React.FC<Props> = ({onValueChange}) => {
     const [value, setValue] = useState<string>('');

     useEffect(() => {
          onValueChange(value)
      }, [value])
  
  

     return (
          <div className="card flex justify-content-center">
               <span className="p-float-label">
                    <InputText
                         id="note" 
                         value={value} 
                         onChange={(e) => setValue(e.target.value)} 
                         style={{height: '100%', width: '100%'}}
                         className="dropdown "
                    />
                    <label htmlFor="note">Concepto</label>
               </span>
          </div>
     )
}
export default TextInput