import React, { useEffect, useState } from 'react'
import '../styles/GenericTableResult.css'
import { Filters, PosventaResult } from '../types';
import { GetPastMonthOfPastYear } from '../helpers';
import { GetPosventaResult } from '../request';

type Props = {
     title: string;
     data: any;
     filters: Filters
}

const ResultTablePosventa: React.FC<Props> = ({title, data, filters}) => {
     
     const [posventaResults, setPosventaResults] = useState<PosventaResult[]>()

     useEffect(() => { 
          initialize();
     },[])

     const initialize = async () => {
          const result = await GetPosventaResult(filters);
          console.log(result)
          setPosventaResults(result);
     }

     const pastMonth = GetPastMonthOfPastYear()
     const formatNumberInMillions = (value: any) => {
          return (Number(value) / 1_000).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
     };
     return (
          <section className="grid-item">
               <span id='tableResultTitle'>{title}</span>
               <table id='tableResultGCon'>
                    <thead>
                         <tr>
                              <th rowSpan={2} >LINEA</th>
                              <th colSpan={4} >Mensual</th>
                              <th colSpan={3} >Anual</th>
                         </tr>
                         <tr>
                              <th>{pastMonth}</th>
                              <th>Objetivo.</th>
                              <th>Resultado</th>
                              <th>%</th>
                              <th>Objetivo.</th>
                              <th>Resultado</th>
                              <th>%</th>
                         </tr>
                    </thead>
                    <tbody>
                    {posventaResults && posventaResults.map((item: PosventaResult, index: any) => (
                         <tr key={index} style={{padding: 0, height: 2}}>
                              <th>{item.department}</th>
                              <td>{formatNumberInMillions(item.pastMonthTotal)}</td>
                              <td>{formatNumberInMillions(item.quantity)}</td>
                              <td>{formatNumberInMillions(item.currentMonthTotal)}</td>
                              <td>{item.currentMonthPercentage}</td>
                              <td>{formatNumberInMillions(item.quantity2)}</td>
                              <td>{formatNumberInMillions(item.currentYearTotal)}</td>
                              <td>{item.currentYearPercentage}</td>
                         </tr>
                    ))}
                    </tbody>
               </table>
          </section>
     )
}

export default ResultTablePosventa