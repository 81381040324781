import React, {FC, useEffect, useState} from 'react'
import { Chart } from 'primereact'
import { Filters } from '../../../types';
import { GetProspectingByEquip, GetProspectingByStatus } from '../request';
import { validateFilters } from '../../../../../../helpers';
import { PROSPECTING_BY_STATUS_CHART_OPTIONS } from '../../../constants';
import '../../../styles/ProspectingByStatus.css'
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import ExternalTooltipHandler from './CustomToolTip';
        
type Props = {
    mainClassName: string;
    filters: Filters;
    setToggleProspecting: any;
    toggleProspecting: any
}

type ProspectingStatus = {
    status: string;        
    qty: number;       
    percentage: string;  
    percentageStatus: string; 
    color:  string;
};

type ProspectingEquip = {
    model: string,
    amount: number,
    type: number
}

//@ts-ignore
const filterAndRemoveType = (data: any, typeValue: any) =>
    //@ts-ignore
    data.filter((item: any) => item.type === typeValue).map(({ type, ...rest }) => rest);

const ProspectingbyStatus: FC<Props> = ({ mainClassName, filters, setToggleProspecting, toggleProspecting }) => {

    const [chartResponses, setChartResponses] = useState<any>()
    const [tooltipResponses, setTooltipResponses] = useState<any>()

    const [prospectingData, setProspectingData] = useState<ProspectingStatus[]>([]);
    const [tooltipData, setTooltipData] = useState<any>([]);


    useEffect(() => {
        const initialize = async () => {
            const chartResponses = await GetProspectingByStatus(filters);
            const tooltipResponses = await GetProspectingByEquip(filters);

            if (!chartResponses) {
                setChartResponses([]);
                return;
            }

            if (!tooltipResponses) {
                setTooltipResponses([]);
                return;
            }

            const tooltipDataUpdated: ProspectingEquip[] = tooltipResponses[toggleProspecting ? 1 : 0];
            const prospectingDataUpdated = chartResponses[toggleProspecting ? 1 : 0];

            const titleLabels = prospectingDataUpdated.map((item: any) => item.percentageStatus);
              
            const p1 = filterAndRemoveType(tooltipDataUpdated, 1);
            const p2 = filterAndRemoveType(tooltipDataUpdated, 2);
            const p3 = filterAndRemoveType(tooltipDataUpdated, 4);
            const p4 = filterAndRemoveType(tooltipDataUpdated, 3);

            setTooltipData({
                [titleLabels[0]]: p1,
                [titleLabels[1]]: p2,
                [titleLabels[2]]: p3,
                [titleLabels[3]]: p4
            })

            setProspectingData(prospectingDataUpdated);
            
            setChartResponses(chartResponses)
            setTooltipResponses(tooltipResponses)
        };
        if(!validateFilters(filters, initialize)){
            setChartResponses([])
            setTooltipResponses([])
            setProspectingData([])
        }
    }, [filters]);

    useEffect(() => {
        console.log(tooltipResponses)
        if(chartResponses){
            const prospectingDataUpdated = chartResponses[toggleProspecting ? 1 : 0];
            setProspectingData(prospectingDataUpdated);
        }

        if (tooltipResponses){
            const prospectingDataUpdated = chartResponses[toggleProspecting ? 1 : 0];
            const tooltipDataUpdated: ProspectingEquip[] = tooltipResponses[toggleProspecting ? 1 : 0];
            const titleLabels = prospectingDataUpdated.map((item: any) => item.percentageStatus);
              
            const p1 = filterAndRemoveType(tooltipDataUpdated, 1);
            const p2 = filterAndRemoveType(tooltipDataUpdated, 2);
            const p3 = filterAndRemoveType(tooltipDataUpdated, 4);
            const p4 = filterAndRemoveType(tooltipDataUpdated, 3);

            setTooltipData({
                [titleLabels[0]]: p1,
                [titleLabels[1]]: p2,
                [titleLabels[2]]: p3,
                [titleLabels[3]]: p4
            })

        }
    }, [toggleProspecting])
    

    const totalAmount = prospectingData.reduce((total, item) => total + item.qty, 0);

    const centerTextPlugin = {
        id: 'centerTextPlugin',
        afterDraw: (chart: any) => {
            const { ctx, chartArea: { width, height } } = chart;
    
            ctx.save();

            const fontSize = (height / 150).toFixed(2);
            ctx.font = `${fontSize}em "Helvetica Neue", Helvetica, Arial, sans-serif`;
            ctx.textBaseline = "middle";
            ctx.textAlign = "center";
            ctx.fillStyle = "rgba(0, 0, 0, 0.6)"; 
    
            const text = `${totalAmount}`;
            const textX = width / 2;
            const textY = height / 2 - 6; 
    
            ctx.fillText(text, textX, textY);
    
            const labelFontSize = (height / 200).toFixed(2); 
            ctx.font = `${labelFontSize}em "Helvetica Neue", Helvetica, Arial, sans-serif`;
            ctx.fillStyle = "rgba(0, 0, 0, 0.5)"; 
    
            const label = "Prospectos";
            const labelX = width / 2;
            const labelY = textY + parseFloat(fontSize) * 16 - 2;
            
            ctx.fillText(label, labelX, labelY);
    
            ctx.restore();
        }
    };

    // const chartOptions = {
    //     ...PROSPECTING_BY_STATUS_CHART_OPTIONS,
    //     plugins: {
    //         ...PROSPECTING_BY_STATUS_CHART_OPTIONS.plugins,
    //         centerTextPlugin,
    //     },
    // };

    const chartOptions2 = {   
        maintainAspectRatio: false,
        aspectRatio: 2.1,
        plugins: {
            legend: {
                display: true,
                position: 'right',
            },
            tooltip: {
                ...PROSPECTING_BY_STATUS_CHART_OPTIONS.plugins,
                centerTextPlugin,
                enabled: false,
                position: 'average',
                external: (e:any) => { ExternalTooltipHandler(e, tooltipData, true) }
            }
        },
    };

    return (
        <div className={mainClassName}>
            <h6>Prospección por Estatus</h6>
            {prospectingData.length > 0 && (
                <div id='toggleProspecting'>
                    <TriStateCheckbox 
                        value={toggleProspecting} 
                        onChange={() => {setToggleProspecting(!toggleProspecting ? true : null)}}
                        tooltip='Mostrar prospectos desde el dia de hoy hasta la fecha definida.'
                        tooltipOptions={{
                            position: 'top'
                        }}
                    />
                    <label id='toggleBtn' className='ml-2'>Solo Vigentes</label>
                </div>
            )}
            
            <Chart 
                type="doughnut" 
                data={{
                    labels: prospectingData.map(item => item.percentageStatus),
                    datasets: [
                        {
                            data: prospectingData.map(item => item.qty),
                            backgroundColor: prospectingData.map(item => item.color), 
                        },
                    ],
                }} 
                options={chartOptions2} 
                style={{ 
                    width: '100%',
                    justifySelf: 'center',
                    maxWidth: '30rem',
                    // height: '5rem',
                    // marginTop:'2.3rem'
                }} 
                plugins={[centerTextPlugin]}
            />
        </div>
    )
}

export default ProspectingbyStatus