import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import './styles.css';
import { CLEAR_PIN, PIN_TREE, UNDO } from '../../../../../assets';
import { MarkerData } from '../../types';

type Props = {
    markers: MarkerData[];
    setMarkers: React.Dispatch<React.SetStateAction<MarkerData[]>>
}

const MarkerControls: React.FC<Props> = ({ markers, setMarkers }) => {
    const undoMarker = () => {
        setMarkers(prevMarkers => {
            const updatedMarkers = [...prevMarkers];
            const removedMarker = updatedMarkers.pop();
            if (removedMarker) {
                //@ts-ignore
                removedMarker.marker.setMap(null);  // Remove marker from map
            }
            return updatedMarkers;
        });
    };

    const clearMarkers = () => {
        markers.forEach(marker => {
            //@ts-ignore
            if (marker.marker) {
                //@ts-ignore
                marker.marker.setMap(null);  // Remove marker from map
            }
        });
        setMarkers([]);
    };

    return (
        <div className="marker-controls">
            <div className="marker-count"><strong>{markers.length}</strong></div>
            

            <div style={{height:1, backgroundColor: 'rgba(0,0,0,0.2)', width:'90%', margin: '1rem 1rem'}}></div>
                  
            <img 
                src={UNDO}
                width={20} 
                onMouseUp={undoMarker}
                className='imgIconMap'
            ></img>
            <img src={CLEAR_PIN} width={20} onMouseUp={clearMarkers}></img>
        </div>
    );
};

const Map: React.FC<Props> = ({ markers, setMarkers }) => {
    //@ts-ignore
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const mapDiv = useRef<HTMLDivElement | null>(null);
    //@ts-ignore
    const markerRefs = useRef<google.maps.Marker[]>([]);  // Reference to added markers

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBYzc3zyKO3bsg1r1OW2gAZV6w_H_nKVOA&callback=initMap`;
        script.defer = true;
        script.async = true;
        //@ts-ignore
        window.initMap = initMap;
        document.head.appendChild(script);

        return () => {
            //@ts-ignore
            delete window.initMap;
            document.head.removeChild(script);
        };
    }, []);

    const initMap = () => {
        //@ts-ignore
        const mapInstance = new window.google.maps.Map(mapDiv.current!, {
            zoom: 10,
            center: { lat: 25.5721, lng: -108.4717 },
            // mapTypeId: 'hybrid',
            mapTypeId: 'terrain'
        });

        setMap(mapInstance);
        //@ts-ignore

        mapInstance.addListener('click', (event: google.maps.MouseEvent) => {
            const newMarker: MarkerData = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
                icon: PIN_TREE,
                text: `Marcador ${markers.length + 1}`,
            };
            //@ts-ignore

            const markerInstance = new window.google.maps.Marker({
                position: { lat: newMarker.lat, lng: newMarker.lng },
                map: mapInstance,
                title: newMarker.text,
                icon: {
                    url: newMarker.icon,
                    //@ts-ignore
                    scaledSize: new window.google.maps.Size(50, 50),
                },
            });

            //@ts-ignore
            newMarker.marker = markerInstance;  // Attach marker to marker data
            markerRefs.current.push(markerInstance);  // Add marker to reference

            setMarkers(prevMarkers => [...prevMarkers, newMarker]);
        });
    };

    useEffect(() => {
        if (map) {
            // Clear existing markers from map
            markerRefs.current.forEach(marker => {
                marker.setMap(null);
            });

            // Add updated markers to map
            markers.forEach((markerData, index) => {
                //@ts-ignore
                const markerInstance = new window.google.maps.Marker({
                    position: { lat: markerData.lat, lng: markerData.lng },
                    map: map,
                    title: markerData.text,
                    icon: {
                        url: markerData.icon,
                        //@ts-ignore
                        scaledSize: new window.google.maps.Size(50, 50),
                    },
                });
                //@ts-ignore
                markerData.marker = markerInstance;  // Attach marker to marker data
                markerRefs.current.push(markerInstance);  // Add marker to reference
            });
        }
    }, [map, markers]);

    return (
        <div style={{width: '100%', height: '100%', position: 'relative'}}>
            <div ref={mapDiv} id="mapRecord" className='mapContainer' style={{ height: '100%', width: '100%' }} />
            {map && ReactDOM.createPortal(
                <MarkerControls markers={markers} setMarkers={setMarkers} />,
                //@ts-ignore
                mapDiv.current!.parentNode!
            )}
        </div>
    );
};

export default Map;
