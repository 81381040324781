import React, { useEffect, useState } from 'react'
import { TreeModuleFilters, TreePin } from '../types'
import { validateFilters } from '../../../../helpers'

type Props = {
     filters: TreeModuleFilters;
     completes: number;
     remainings: number;
     markers: TreePin[];
}
const Count:React.FC<Props> = ({filters, completes, remainings, markers}) => {

    const [planteds, setPlanteds] = useState<number>(0);
    const [remaining, setRemaining] = useState<number>(0);

     useEffect(() => {
          
          let startTime: any;
          const startAnimation = () => {
               startTime = Date.now();
               
               const animationInterval = setInterval(() => {
                    const currentTime = Date.now();
                    const elapsedTime = currentTime - startTime;

                    if (elapsedTime < 1000) {
                         const progress = Math.min(1, elapsedTime / 1000);
                         setPlanteds(Math.floor(progress * completes));
                         setRemaining(Math.floor(progress * remainings));

                    } else {
                         setPlanteds(completes);
                         setRemaining(remainings)
                         clearInterval(animationInterval);
                    }
               }, 1);
          }
          validateFilters(filters, startAnimation)
     }, [filters, markers])
    
     return (
          <div className='countContent'>
               <section>
                    <span className='treeNumber planteds'>{planteds}</span>
                    <span className='planteds treeCountDesc'>Plantados</span>
               </section>
               <div style={{
                    // height:1, 
                    backgroundColor: 'rgba(0,0,0,0.2)', 
                    width:'90%', 
                    margin: '1rem'
               }}/>
               <section>
                    <span className='treeNumber remaining'>{remaining}</span>
                    <span className='remaining treeCountDesc'>Pendientes</span>
               </section>
          </div>
     )
}
export default Count