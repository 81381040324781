import { Button, Dialog, Toast } from 'primereact'
import React, { useState, useRef } from 'react'
import { FileUploadComponent } from './UploadMoreEvidences'
import { saveTreePhotos } from '../../requests/Trees'
import { TreePin } from '../../types'

type Props = {
     pin: TreePin,
     refreshPhotos: () => void
}

const Actions: React.FC<Props> = ({pin, refreshPhotos}) => {

     const toast = useRef(null)

     const [images, setImages] = useState<File[]>([])
     const [showAddEvidences, setShowAddEvidences] = useState<boolean>(false)

     const handleSetImages = async (files: File[] | undefined) => {
          
          if(!files) return;
          setImages(files)

          const isAdditional = pin.recordId !== 0 ;
          const folderId = isAdditional? pin.recordId : pin.orderId;

          const arePhotosSaved = await saveTreePhotos(files, folderId, isAdditional)

          if(arePhotosSaved){
               //@ts-ignore
               toast.current?.show({ 
                    severity: 'success', 
                    // summary: 'Falta completar lo siguiente', 
                    // detail: templateInvalids, 
                    life: 3000, 
                    content: (
                         <section className="flex flex-column" style={{ flex: '1' }}>
                              <span className="font-bold text-lg mb-2">Fotos subidas correctamente.</span>
                              
                         </section>
                    )
               });
               refreshPhotos();
          }
          else {
               console.log('no se guardaron las fotos')
          }

     }

     const hideFileUpload = () => {
          setShowAddEvidences(false)
     }


     return (
          <section>
               <div style={{height: 1, backgroundColor: 'rgba(0,0,0,0.1)', width: '97%', margin: '0.5rem'}}></div>
               <section id="btnAddMoreSec" className=''>
                    <Button 
                         // label={newTreeRecord.images.length === 0 ? 'Subir Fotos' : `Cambiar Fotos (${newTreeRecord.images.length})` }
                         label="Subir Fotos"
                         severity='info'
                         size='small'
                         // text
                         style={{ 
                              width: '8rem',
                              padding: '0.3rem 0.5rem',
                         }}
                         icon={"pi pi-upload"}
                         onClick={()=>{setShowAddEvidences(true)}}
                    />
               </section>
               <Dialog
                    visible={showAddEvidences} 
                    onHide={() => setShowAddEvidences(false)} 
                    header={'Evidencias'}
                    style={{width: '50vw'}}
                    position='top'
               >
                    <FileUploadComponent 
                         setFiles={handleSetImages}
                         files={images}
                         hideModal={hideFileUpload}
                    />
               </Dialog>
               <Toast
                    ref={toast} 
                    position="center" 
                    
               ></Toast>
          </section>
     )
}

export default Actions