import React, { useEffect, useState } from 'react';
import '../styles/GenericTableResult.css';
import {ActivesQualityWalletType, Filters } from '../types';
import { GetPastMonthOfPastYear } from '../helpers';
import { GetActivesQualityWallet } from '../request';

type Props = {
    title: string;
    data: any;
    filters: Filters
}

const ActivesQualityWallet: React.FC<Props> = ({ title, data, filters }) => {


    const [walletResults, setWalletResults] = useState<ActivesQualityWalletType[]>()

    useEffect(() => {
         initialize();
    },[])

    const initialize = async () => {
         const result = await GetActivesQualityWallet(filters);
         setWalletResults(result)
    }

    const pastMonth = GetPastMonthOfPastYear();

    const formatNumberInMillions = (value: any) => {
         return (Number(value)).toLocaleString('en-US', { 
            minimumFractionDigits: 2, 
            maximumFractionDigits: 2 
        });
    };

    return (
        <section className="grid-item">
            <span id='tableResultTitle'>{title}</span>
            <table id='tableResultGCon'>
                <thead>
                    <tr>
                        <th rowSpan={2}>Cartera</th>
                        <th colSpan={4}>Objetivos 2024</th>
                        <th colSpan={3}>Cierre</th>
                    </tr>
                    <tr>
                        <th>Marzo</th>
                        <th>Abril</th>
                        <th>Hoy</th>
                        <th>Ppto</th>
                        <th>2023</th>
                        <th>2022</th>
                    </tr>
                </thead>
                <tbody>
                    {walletResults && walletResults.map((item: ActivesQualityWalletType, index: any) => (
                        <tr key={index} style={{ padding: 0, height: 2 }}>
                            <th>{item.concept}</th>
                            <td>{formatNumberInMillions(item.secondPastMonth)}</td>
                            <td>{formatNumberInMillions(item.firstPastMonth)}</td>
                            <td>{formatNumberInMillions(item.today)}</td>
                            <td>{formatNumberInMillions(item.budget)}</td>
                            <td>{formatNumberInMillions(item.firstPastYear)}</td>
                            <td>{formatNumberInMillions(item.secordPastYear)}</td>

                        </tr>
                    ))}
                </tbody>
            </table>
        </section>
    );
}

export default ActivesQualityWallet;
