import React from 'react'
import  CustomDataTable  from '../../../../general_components/CustomDataTable.tsx';

const Summary = ({summaryData}) => {
     console.log(Date.now());




     
     return (
          <div>
               <CustomDataTable 
                    tableTitle={"Detalle del presupuesto y avance por asesor."}
                    data={summaryData}
               />
          </div>
     )
}

export default Summary