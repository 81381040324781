export const SummaryMockData = [
     {
       "DEPARTMENT": "Refacciones",
       "SELLER_NAME": "Gonzalez Lopez Jose Alfredo",
       "BUDGET": 19017147.58,
       "ADVANCEMENT": 17506998.6,
       "PERCENT": 0.8058,
       "GROSSPROFIT": 6551721.73,
       "MARGIN": 0.3742,
       "PERCENT_REMAINING": 0.2634,
       "PENDING_INVOICING": 1510148.98
     },
     {
       "DEPARTMENT": "Refacciones",
       "SELLER_NAME": "Calderon Valle Martin",
       "BUDGET": 19017147.58,
       "ADVANCEMENT": 12590428.14,
       "PERCENT": 0.5634,
       "GROSSPROFIT": 4462394.15,
       "MARGIN": 0.3544,
       "PERCENT_REMAINING": 0.4376,
       "PENDING_INVOICING": 6426719.44
     },
     {
       "DEPARTMENT": "Refacciones",
       "SELLER_NAME": "Monje Parra Arnold Alfredo",
       "BUDGET": 19017147.58,
       "ADVANCEMENT": 18856679.27,
       "PERCENT": 0.825,
       "GROSSPROFIT": 6271345.25,
       "MARGIN": 0.3325,
       "PERCENT_REMAINING": 0.2596,
       "PENDING_INVOICING": 160468.31
     },
     {
       "DEPARTMENT": "Ventas",
       "SELLER_NAME": "Sandoval Trasviña Saul",
       "BUDGET": 1888500,
       "ADVANCEMENT": 1362571.8,
       "PERCENT": 0.9119,
       "GROSSPROFIT": 486603.46,
       "MARGIN": 0.3571,
       "PERCENT_REMAINING": 0.5544,
       "PENDING_INVOICING": 525928.2
     },
     {
       "DEPARTMENT": "Ventas",
       "SELLER_NAME": "Ruiz Agramon Joavany Antonio",
       "BUDGET": 1594040,
       "ADVANCEMENT": 1809238.2,
       "PERCENT": 1.3876,
       "GROSSPROFIT": 606774.72,
       "MARGIN": 0.3353,
       "PERCENT_REMAINING": 0.4547,
       "PENDING_INVOICING": -215198.2
     },
     {
       "DEPARTMENT": "Ventas",
       "SELLER_NAME": "Ureta Cazarez Juan Carlos",
       "BUDGET": 3075660,
       "ADVANCEMENT": 3463960.57,
       "PERCENT": 1.7391,
       "GROSSPROFIT": 1152643.31,
       "MARGIN": 0.3327,
       "PERCENT_REMAINING": 0.5428,
       "PENDING_INVOICING": -388300.57
     },
     {
       "DEPARTMENT": "Ventas",
       "SELLER_NAME": "Loredo Meza Diego Armando",
       "BUDGET": 1800420,
       "ADVANCEMENT": 1885712.34,
       "PERCENT": 1.6552,
       "GROSSPROFIT": 487299.59,
       "MARGIN": 0.2584,
       "PERCENT_REMAINING": 0.3345,
       "PENDING_INVOICING": -85292.34
     },
     {
       "DEPARTMENT": "Ventas",
       "SELLER_NAME": "Haro Heredia Ramon",
       "BUDGET": 1349800,
       "ADVANCEMENT": 1460309.85,
       "PERCENT": 1.426,
       "GROSSPROFIT": 383605.75,
       "MARGIN": 0.2626,
       "PERCENT_REMAINING": 0.4434,
       "PENDING_INVOICING": -110509.85
     }
   ]
   