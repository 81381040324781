import React from 'react';
import Inicio from './modules/Inicio';


import { BrowserRouter, Routes, Route } from 'react-router-dom';
import SeguimientoSurtido from './modules/Refacciones/SeguimientoSurtido/SeguimientoSurtido'
import SeguimientoClientes from './modules/Refacciones/SeguimientoClientes/SeguimientoClientes';
import BudgetAdvancement from './modules/Refacciones/BudgetAdvancement'
import Dashboard from './modules/Ventas/Dashboard';
import Pops from './modules/Servicio/Pops/index.tsx';
import TreeAnalitics from './modules/Clientes/ArbolesPlantados/index.tsx';
import WeeklyDirectionReport from './modules/Administracion/WeeklyDirectionReport/index.tsx'


const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/seguimientosurtido' element={<SeguimientoSurtido />} />
                <Route path='/seguimientoclientes' element={<SeguimientoClientes />} />
                <Route path='/scrnba' element={<BudgetAdvancement />} />
                <Route path='/scrndv' element={<Dashboard />} />
                <Route path='/scrnpps' element={<Pops/>} />
                <Route path='/scrnnltcstrs' element={<TreeAnalitics/>} />
                <Route path='/scrnrprtdrctn' element={<WeeklyDirectionReport/>} />
                <Route path='/' element={<Inicio />} />
            </Routes>
        </BrowserRouter>
    )
}

export default App;
