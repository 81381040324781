import React, { useState, useEffect } from 'react';
import ReactSpeedometer from 'react-d3-speedometer';
import { calculatePercentage, validateFilters } from '../../../../helpers';

const CardDataRI = ({ percentIndicator, endRemaning, endPercent, endAmount, filters }) => {
    const [percent, setPercent] = useState(0);
    const [amount, setAmount] = useState(0);

    const [remaning, setRemaning] = useState(0);


    useEffect(() => {
        let startTime;
        setPercent((calculatePercentage(endRemaning, endAmount)) > 100 ? 100 : (calculatePercentage(endRemaning, endAmount)));

        const startAnimation = () => {
            startTime = Date.now();

            const animationInterval = setInterval(() => {
                const currentTime = Date.now();
                const elapsedTime = currentTime - startTime;

                if (elapsedTime < 1000) {
                    const progress = Math.min(1, elapsedTime / 1000);
                    setAmount(Math.floor(progress * endAmount));

                    setRemaning(Math.floor(progress * endRemaning));
                } else {
                    setAmount(endAmount);

                    setRemaning(endRemaning);
                    clearInterval(animationInterval);
                }
            }, 1);
        };

        const p = document.querySelectorAll('.panelin')

        p.forEach((item) => {
            const pp = item.parentElement
            pp.style.padding = 0;

        })

        if (!validateFilters(filters, startAnimation)) {
            setPercent(0);
            setAmount(0);
            setRemaning(0)
        }
    }, [endPercent, endAmount, filters, endRemaning]);

    return (
        <div className='panel' style={{ padding: 0, width:'100%' }}>
            <div className='panelin' style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
                <div className='cards-sections'>
                    <h4 style={{ fontSize: '80%', fontWeight: '600', marginBottom: 0 }}   >{percentIndicator}</h4>
                    <ReactSpeedometer
                        maxValue={100}
                        value={percent}
                        currentValueText={`${percent.toFixed(0)}%`}
                        needleColor="gray"
                        startColor="red"
                        endColor="rgb(0, 60, 0)"
                        width={200}
                        height={125}
                        fluidWidth={false}
                        ringWidth={30}
                        needleHeightRatio={0.8}
                        needleTransitionDuration={2000}
                        segments={500}
                        maxSegmentLabels={0}
                    />
                </div>
                <div style={{ width: '80%', borderTop: '1px solid lightgray' }}></div>
                <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
                    <div className='cards-sections'>
                        <h4 style={{ fontSize: '70%', marginBottom: 5 }}>Avance de Facturación</h4>
                        <h5>{remaning.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</h5>
                    </div>

                    <div style={{ height: '80%', borderLeft: '1px solid lightgray' }}></div>
                    <div className='cards-sections'>
                        <h4 style={{ fontSize: '70%', marginBottom: 5 }}>Meta de facturación</h4>
                        <h5>{amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</h5>
                    </div>
                </div>
            </div>
            {/* <h2>{percent.toLocaleString('en-US', { style: 'percent', currency: 'USD' })}</h2>

        <span>hola 1</span>*/}
        </ div>

    );
};

export default CardDataRI;
