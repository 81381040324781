
const DEFAULT_VALUES = {
    percent: 0,
    amount: 1101668.67
};

const useProcessedData = (dataFetched) => {

    if (dataFetched == null) return {
        budgetAvancement: DEFAULT_VALUES,
        grossProfitAndMargin: DEFAULT_VALUES,
        remainInvoicing: DEFAULT_VALUES
    };
    else {
        // console.log(dataFetched)
    }

    const baPercent = dataFetched.reduce((total, item) => total + item.PERCENT, 0);
    const baPercentAvg = baPercent / Math.max(1, dataFetched.length);

    const irPercent = dataFetched.reduce((total, item) => total + item.PERCENT_REMAINING, 0);
    const irPercentAvg = irPercent / Math.max(1, dataFetched.length);


    const gpPercent = dataFetched.reduce((total, item) => total + item.MARGIN, 0);
    const gpPercentAvg = gpPercent / Math.max(1, dataFetched.length);

    const budgetAvancement = {
        percent: +(baPercentAvg).toFixed(2),
        amount: dataFetched.reduce((total, item) => total + item.BUDGET, 0)
    };

    const grossProfitAndMargin = {
        percent: +(gpPercentAvg).toFixed(2),
        amount: dataFetched.reduce((total, item) => total + item.GROSSPROFIT, 0)
    };

    const remainInvoicing = {
        percent: +(irPercentAvg).toFixed(2),
        amount: dataFetched.reduce((total, item) => total + item.PENDING_INVOICING, 0)
    };

    return {
        budgetAvancement,
        grossProfitAndMargin,
        remainInvoicing
    };

};

export default useProcessedData;
