import React, { useEffect, useRef, useState } from 'react'
import Card from '../../../../../general_components/Card'
import DropdownBranch from '../../../../../general_components/DropdownBranch'
import './styles.css'
import DropdownEmployeeSingle from '../../../../../general_components/DropdownEmployeeSingle'
import DropdownDepartmentSingle from '../../../../../general_components/DropdownDepartmentSingle'
import TextArea from '../../../../../general_components/TextArea'
import TextInput from '../../../../../general_components/TextInput'
import { Dialog, Button } from 'primereact'
import { Toast } from 'primereact/toast'
import { FileUploadComponent } from './FileUpload'
import { validateEntries } from '../../../../../helpers'
import { MarkerData, NewTreeRecord, NewTreeRecordDetail } from '../../types'
import DatePicker from '../../../../../general_components/DatePicker'
import { saveData, saveDataDetail, saveTreePhotos } from '../../requests/Trees'

const DEFAULT_RECORD: NewTreeRecord = {
     branch: -1,
     department: null,
     employee: -1,
     date: null,
     concept: '',
     notes: '',
     images: []
}

type Props = {
     markers: MarkerData[],
     finishRecord: () => void
}

const MapData: React.FC<Props> = ({markers, finishRecord}) => {

     const toastCenter = useRef(null);

     const [newTreeRecord, setNewTreeRecord] = useState<NewTreeRecord>(DEFAULT_RECORD)
     const [showNewRecord, setShowNewRecord] = useState<boolean>(false)

     

     const setImages = (files: File[] | undefined) => {
          
          if(!files) return;

          setNewTreeRecord({
               ...newTreeRecord,
               images: files
          })

     }

     const hideFileUpload = () => {
          setShowNewRecord(false)
     }

     const showInvalids = (invalidEntries: string[]) => {
          //@ts-ignore
          toastCenter.current?.show({ 
               severity: 'error', 
               // summary: 'Falta completar lo siguiente', 
               // detail: templateInvalids, 
               life: 3000, 
               content: (
                    <section className="flex flex-column" style={{ flex: '1' }}>
                         <span className="font-bold text-lg mb-2">Aún no puedes guardar los cambios.</span>
                         
                         <span className="mb-1">Falta completar lo siguiente:</span>
                         
                         <ul>
                              {
                                   invalidEntries.map(item => <li key={item}>{item}</li>)
                              }
                         </ul>
                    </section>
               )
          });
     }

     const handleSaveTree = async () => {
          try {
               const entriesToValidate = {...newTreeRecord, markers }
               delete entriesToValidate.notes
     
               const result = validateEntries(entriesToValidate)
     
               if(!result.isValid) {
                    showInvalids(result.invalidEntries)
                    return
               }

               

               const recordId = await saveData(newTreeRecord)

               const treePlateds: NewTreeRecordDetail[] = markers.map(item => {
                    return {
                         recordId: recordId,
                         lat: item.lat,
                         lng: item.lng
                    }
               })

               await saveDataDetail(treePlateds)
               
               const isAllSaved = await saveTreePhotos(newTreeRecord.images, recordId, true)

               if(isAllSaved){
                    console.warn('todo como hueso padrino')
                    finishRecord();
               }

          } catch (error) {
               console.error(error)
          }

     }    

     return (
          <Card title='Datos del Registro' height='100%'>
          {/* <div className='panel'> */}
               <section className='dataSections'>
                    <DropdownBranch
                         withoutAll
                         onValueChange={(value)=>setNewTreeRecord({
                              ...newTreeRecord,
                              branch: value as number
                         })}
                    />
                    <DropdownDepartmentSingle
                         onValueChange={(value)=>setNewTreeRecord({
                              ...newTreeRecord,
                              department: value
                         })}
                    />
                    <DropdownEmployeeSingle
                         departments={newTreeRecord.department}
                         branch={newTreeRecord.branch}
                         onValueChange={(value)=>setNewTreeRecord({
                              ...newTreeRecord,
                              employee: value 
                         })}
                    />
                    {/* <span className="p-float-label">
                         <Calendar
                              inputId="date"
                              value={null}
                              style={{ height: '30px', width: '100%', padding: 0, textAlign: 'center' }}
                              dateFormat="DD, dd/mm/yy"
                         />
                         <label htmlFor="date">Fecha Plantación</label>
                    </span> */}
                    <DatePicker 
                         text='Fecha de Plantación'
                         onDateChange={(value)=>setNewTreeRecord({
                              ...newTreeRecord,
                              date: value 
                         })}
                    />
                   
                    
                    <TextInput
                         onValueChange={(value)=>setNewTreeRecord({
                              ...newTreeRecord,
                              concept: value
                         })}
                    />
                    <TextArea
                         onValueChange={(value)=>setNewTreeRecord({
                              ...newTreeRecord,
                              notes: value
                         })}
                    />
                    
                    <div className='imagesUploadSection'>
                         <Button 
                              label={newTreeRecord.images.length === 0 ? 'Subir Fotos' : `Cambiar Fotos (${newTreeRecord.images.length})` }
                              severity='info'
                              size='small'
                              style={{
                                   width: '100%',
                                   padding: newTreeRecord.images.length === 0 ? '0.5rem 3rem' : '0.5rem 2rem'
                              }}
                              icon={newTreeRecord.images.length === 0 ? "pi pi-upload" : "pi pi-images"}
                              onClick={()=>{setShowNewRecord(!showNewRecord)}}
                         />

                         {/* <span className='text-center' >
                              {newTreeRecord.images.length !== 0 &&
                                   (newTreeRecord.images.length +  (newTreeRecord.images.length > 1 ? ' Fotos Cargadas' : ' Foto Cargada'))
                                   
                              }
                         </span> */}
                    </div>

                    <div className='recountTrees'>
                         <div style={{
                              height:1, 
                              backgroundColor: 'rgba(0,0,0,0.2)', 
                              width:'90%', 
                              margin: '0 1rem'
                         }}/>
                         <Button 
                              label='Guardar Registro'
                              severity='success'
                              className='custom-button '
                              style={{
                                   width: '100%',
                                   paddingLeft: '2rem'
                              }}
                              icon="pi pi-save"
                              onClick={handleSaveTree}
                         />
                    </div>

                    <Dialog
                         visible={showNewRecord} 
                         onHide={() => setShowNewRecord(false)} 
                         header={'Evidencias'}
                         style={{width: '50vw'}}
                         position='top'
                    >
                         <FileUploadComponent 
                              setFiles={setImages}
                              files={newTreeRecord.images}
                              hideModal={hideFileUpload}
                         />
                    </Dialog>
                    <Toast
                         ref={toastCenter} 
                         position="center" 
                         
                    ></Toast>
               </section>
          </Card>
     )
}

export default MapData