import React, { useState, useEffect } from 'react';
import ReactSpeedometer from 'react-d3-speedometer';
import { validateFilters } from '../../../../helpers';

const CardData = ({ percentIndicator, amountIndicator, endPercent, endAmount, filters, invertColors }) => {
    const [percent, setPercent] = useState(0);
    const [amount, setAmount] = useState(0);

    useEffect(() => {
        let startTime;
        setPercent(endPercent);

        const startAnimation = () => {
            startTime = Date.now();

            const animationInterval = setInterval(() => {
                const currentTime = Date.now();
                const elapsedTime = currentTime - startTime;

                if (elapsedTime < 1000) {
                    const progress = Math.min(1, elapsedTime / 1000);
                    setAmount(Math.floor(progress * endAmount));
                } else {
                    setAmount(endAmount);
                    clearInterval(animationInterval);
                }
            }, 1);
        };

        const p = document.querySelectorAll('.panelin')

        p.forEach((item) => {
            const pp = item.parentElement
            pp.style.padding = 0;

        })

        if (!validateFilters(filters, startAnimation)) {
            setPercent(0);
            setAmount(0);
        }
    }, [endPercent, endAmount, filters]);

    return (
        <div className='panel' style={{ padding: 0, width:'100%'}}>
            <div className='panelin' style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
                <div className='cards-sections'>
                    <h4 style={{ fontSize: '80%', fontWeight: '600', marginBottom: 0 }}   >{percentIndicator}</h4>
                    <ReactSpeedometer
                        maxValue={100}
                        value={percent * 100}
                        currentValueText={`${(percent * 100).toFixed(0)}%`}
                        needleColor="gray"
                        startColor={invertColors ? "red" : 'rgb(0, 60, 0)'}
                        endColor={!invertColors ? "red" : 'rgb(0, 60, 0)'}
                        width={200}
                        height={125}
                        fluidWidth={false}
                        ringWidth={30}
                        needleHeightRatio={0.8}
                        needleTransitionDuration={2000}
                        segments={500}
                        maxSegmentLabels={0}
                    />
                </div>
                <div style={{ width: '80%', borderTop: '1px solid lightgray' }}></div>
                <div className='cards-sections'>
                    <h4 style={{ fontSize: '70%', marginBottom: 5 }}>{amountIndicator}</h4>
                    <h5>{(amount < 0 ? 0 : amount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</h5>
                </div>
            </div>
            {/* <h2>{percent.toLocaleString('en-US', { style: 'percent', currency: 'USD' })}</h2>

        <span>hola 1</span>*/}
        </ div>

    );
};

export default CardData;
