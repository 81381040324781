import React, { useState } from 'react'
import './styles.css'
import RecordMap from './RecordMap'
import RecordData from './RecordData'
import { MarkerData } from '../../types'

const AddRecord = ({finishRecord}: {finishRecord: () => void}) => {


     const [markers, setMarkers] = useState<MarkerData[]>([]);

     return (
               <main className='addContainer'>
                    <section className='entriesSection'>
                         <RecordData
                              markers={markers}
                              finishRecord={finishRecord}
                         />
                    </section>
                    <section>
                         <RecordMap 
                              setMarkers={setMarkers}
                              markers={markers}
                         />
                    </section>
               </main>
     )
}

export default AddRecord