

/**
 * Get String with the month (first 3 letters) and year (last two digits) from past year 
 * 
 */
export const GetPastMonthOfPastYear = (): string => {
     const date = new Date();
     const monthNames = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
     const currentMonth = monthNames[date.getMonth() -1];
     const lastYear = (date.getFullYear() - 1).toString().slice(-2);
 
     return `${currentMonth}-${lastYear}`;
 };
 
 