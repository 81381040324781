/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Dropdown } from 'primereact'
import { SUCURSAL_OPTIONS, SUCURSAL_OPTIONS_WITHOUT_ALL } from '../constants/constants.ts'

type Props = {
    onValueChange: (param: number | null)=>void
    withoutAll?: boolean
}

const DropdownBranch:React.FC<Props> = ({ onValueChange, withoutAll }) => {

    const [branchSelected, setBranchSelected] = useState(null)

    useEffect(() => {
        onValueChange(branchSelected)
    }, [branchSelected])


    return (
        <span className="p-float-label panel-element">
            <Dropdown
                inputId="dd-sucursal"
                value={branchSelected}
                options={withoutAll ? SUCURSAL_OPTIONS_WITHOUT_ALL: SUCURSAL_OPTIONS}
                onChange={(e: any) => setBranchSelected(e.value)}
                placeholder="Sucursal"
                className="dropdown"
            />
            <label htmlFor="dd-sucursal">Sucursal</label>
        </span>
    )
}

export default DropdownBranch

