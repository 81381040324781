import React, { FC, useEffect, useState } from 'react';
import { Column, DataTable } from 'primereact';
//@ts-ignore
import { TableHeader } from './TableHeader.js';
import { columnStyle, formatBody } from '../helpers/formatTableColumn.ts';

type Props = {
    tableTitle: string;
    data: any;
};

type ColumnDefinition = {
    key: string;
    prefix: string;
};

const CustomDataTable: FC<Props> = ({ tableTitle, data }) => {

    const [columns, setColumns] = useState<ColumnDefinition[]>([]);
    const [formattedData, setFormattedData] = useState<any[]>([]);

    useEffect(() => {
        if (data.length > 0) {
            const detectedColumns = Object.keys(data[0]).map((rawKey) => ({
                prefix: rawKey.slice(0, 1), 
                key: rawKey.slice(1),
            }));
            setColumns(detectedColumns);

            const cleanedData = data.map((item:any) => {
                const cleanedItem: any = {};
                Object.keys(item).forEach((key) => {
                    cleanedItem[key.slice(1)] = item[key];
                });
                return cleanedItem;
            });
            setFormattedData(cleanedData);
        }
    }, [data]);

    return (
        <div>
            <DataTable
                value={formattedData}
                size="small"
                header={TableHeader(tableTitle, formattedData)}
                paginator
                scrollable
                rows={10}
                selectionMode="single"
                rowsPerPageOptions={[5, 10, 25, 50, -1]}
                tableStyle={{ minWidth: '50rem' }}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords}"
                showGridlines
                editMode="row"
            >
                {columns.map((col, index) => (
                    <Column
                        key={index}
                        field={col.key} // Departamento sDepartamento
                        header={col.key}
                        sortable
                        style={{...columnStyle(col.prefix), fontSize:'100%'}}
                        headerStyle={{ textAlign: 'center'}}
                        headerClassName={'p-text-center'}
                        body={(rowData: any) =>
                            formatBody(rowData[col.key], col.prefix)
                        }
                    />
                ))}
            </DataTable>
        </div>
    );
};

export default CustomDataTable;
