import React, { useEffect, useState } from 'react';
import { Calendar } from 'primereact';

type Props = {
     text: string;
     onDateChange: (param: Date | null) => void,
};

const DatePicker: React.FC<Props> = ({ text, onDateChange }) => {
     const [date, setDate] = useState<Date | null>(null);
     
     useEffect(() => {
          onDateChange(date);
     }, [date]);

     return (
          <span className="p-float-label">
               <Calendar
                    inputId="date"
                    value={null}
                    onChange={(e: any) => {setDate(e.value)}} 
                    style={{ height: '30px', width: '100%', padding: 0, textAlign: 'center' }}
                    dateFormat="DD, dd/mm/yy"
               />
               <label htmlFor="date">{text}</label>
          </span>
     );
};

export default DatePicker;