export const TRACTOR = [{
     model: '',
     amount: 0
}]

export const BUDGET_SOLD = {
     budget: TRACTOR,
     sold: TRACTOR
}

export const TRACTOR_DETAIL_DATA_DEFAULT = {
     amount: 0,
     detail: TRACTOR
}

export const MARKET_SHARE_DEFAULT_DATA = {
     brands: ['Valentin'],
     amounts: [1],
     colors: ["red"]
};