import CardChart from './CardChart';
const TopCustomersAndGrossProfit = ({ grossProfitData, marginData }) => {
    return (
        <section className='customers-gp'>
            <CardChart
                title={'Utilidad Bruta Ultimos 12 meses'}
                data={grossProfitData}
            />
            <CardChart
                title={'Margen Ultimos 12 meses'}
                data={marginData}
            />
        </section>
    )
}

export default TopCustomersAndGrossProfit;