import React, { useEffect, useState } from 'react';
import './styles.css'; // Asegúrate de importar tu archivo de estilos
import { LOGO_AG } from '../../../assets';
import ResultTableMaquinary from './components/ResultTableMaquinary';
import ActivesQualityWallet from './components/ActivesQualityWallet';
import JohnDeereIndicators from './components/JohnDeereIndicators';
import InventoryUnits from './components/InventoryUnits';
import Highlights from './components/Highlights';
import FinancialIndicators from './components/FinancialIndicators';
import ResultTablePosventa from './components/ResultTablePosventa';
import ActivesQualityInventory from './components/ActivesQualityInventory';
import TendencyWallet from './components/TendencyWallet';
import TendencyInventory from './components/TendencyInventory';
import { Filters } from './types';

const DEFAULT_FILTERS = {
    branch: 2,
    from: '2024-1-1',
    to: '2024-12-31',
    department: -1
}

const data1 = [
     {
       linea: 'Tractores',
       mensual: { apr23: 32, ppto: 10, avance: 4, porcentaje: '40%' },
       anual: { ppto: 230, avance: 57, porcentaje: '25%' }
     },
     {
       linea: 'Autoguiado',
       mensual: { apr23: 5, ppto: 2, avance: 0, porcentaje: '0%' },
       anual: { ppto: 51, avance: 12, porcentaje: '24%' }
     },
     {
       linea: 'Usados',
       mensual: { apr23: 8, ppto: 6, avance: 2, porcentaje: '33%' },
       anual: { ppto: 86, avance: 20, porcentaje: '23%' }
     },
     {
       linea: 'Totales',
       mensual: { apr23: 45, ppto: 18, avance: 6, porcentaje: '33%' },
       anual: { ppto: 367, avance: 89, porcentaje: '24%' }
     }
   ];
   const data2 = [
     {
       linea: 'Refacciones',
       mensual: { apr23: 18, objetivo: 18645, resultado: 16535, porcentaje: '53%' },
       anual: { objetivo: 216252, resultado: 42351, porcentaje: '20%' }
     },
     {
       linea: 'Agroferretera',
       mensual: { apr23: 4, objetivo: 4969, resultado: 4361, porcentaje: '45%' },
       anual: { objetivo: 46361, resultado: 12588, porcentaje: '27%' }
     },
     {
       linea: 'Servicio',
       mensual: { apr23: 6, objetivo: 6325, resultado: 5464, porcentaje: '49%' },
       anual: { objetivo: 49876, resultado: 13396, porcentaje: '27%' }
     },
     {
       linea: 'Totales',
       mensual: { apr23: 29, objetivo: 29939, resultado: 26360, porcentaje: '51%' },
       anual: { objetivo: 312489, resultado: 68335, porcentaje: '22%' }
     }
   ];

   const datosCartera = [
     {
         linea: "Cartera Total",
         mensual: {
             apr23: 47800,
             objetivo: 62135,
             resultado: 66730,
             porcentaje: "14%"
         },
         anual: {
             objetivo: 76308,
             resultado: 47079,
             porcentaje: "26%"
         }
     },
     {
         linea: "Cartera Vencida",
         mensual: {
             apr23: 7585,
             objetivo: 6868,
             resultado: 9228,
             porcentaje: "14%"
         },
         anual: {
             objetivo: 12078,
             resultado: 12382,
             porcentaje: "26%"
         }
     },
     {
         linea: "Porcentaje de Vencimiento",
         mensual: {
             apr23: "16%",
             objetivo: "11%",
             resultado: "14%",
             porcentaje: "16%"
         },
         anual: {
             objetivo: "16%",
             resultado: "26%",
             porcentaje: "113%"
         }
     }
 ];
 

 const datosInventario = [
     {
         linea: "Inventario Total",
         mensual: {
             marzo: 71861,
             abril: 67193,
             hoy: 67618,
             mayo: 70886
         },
         anual: {
             "2023": 123232,
             "2022": 68342
         }
     },
     {
         linea: "Inventario 120 días",
         mensual: {
             marzo: 39716,
             abril: 31404,
             hoy: 31245,
             mayo: 13023
         },
         anual: {
             "2023": 67191,
             "2022": 17139
         }
     },
     {
         linea: "Inventario de soporte",
         mensual: {
             marzo: 606,
             abril: 634,
             hoy: 558,
             mayo: 738
         },
         anual: {
             "2023": 792,
             "2022": 626
         }
     }
 ];
 
//  const datosCarteraChart = {
//      labels: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
//      datasets: [
//          {
//              label: 'Cartera Total',
//              data: [50000, 52000, 47800, 62135, 66730, 68000, 69000, 72000, 75000, 76308, 77000, 78000],
//              fill: false,
//              borderColor: '#42A5F5',
//              tension: 0.4
             
//          },
//          {
//              label: 'Cartera Vencida',
//              data: [8000, 8200, 7585, 6868, 9228, 9400, 9500, 9800, 10000, 12078, 12382, 12500],
//              fill: false,
//              borderColor: '#FFA726',
//              tension: 0.4,
//              width: 0.5
//          }
//      ]
//  };
function formatNumberWithCommas(number:any) {
     return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
 }
 
 const datosCarteraChart = [
     ['Mes', 'Cartera Total', { role: 'annotation' }, 'Cartera Vencida', { role: 'annotation' }],
     ['Ene', 50000, formatNumberWithCommas(50000), 7500, formatNumberWithCommas(7500)],
     ['Feb', 52000, formatNumberWithCommas(52000), 8200, formatNumberWithCommas(8200)],
     ['Mar', 47800, formatNumberWithCommas(47800), 9500, formatNumberWithCommas(9500)],
     ['Abr', 62135, formatNumberWithCommas(62135), 10000, formatNumberWithCommas(10000)],
     ['May', 66730, formatNumberWithCommas(66730), 12000, formatNumberWithCommas(12000)],
     ['Jun', 68000, formatNumberWithCommas(68000), 10500, formatNumberWithCommas(10500)],
     ['Jul', 69000, formatNumberWithCommas(69000), 9000, formatNumberWithCommas(9000)],
     ['Ago', 72000, formatNumberWithCommas(72000), 8500, formatNumberWithCommas(8500)],
     ['Sep', 75000, formatNumberWithCommas(75000), 9500, formatNumberWithCommas(9500)],
     ['Oct', 76308, formatNumberWithCommas(76308), 11000, formatNumberWithCommas(11000)],
     ['Nov', 77000, formatNumberWithCommas(77000), 11800, formatNumberWithCommas(11800)],
     ['Hoy', 78000, formatNumberWithCommas(78000), 12500, formatNumberWithCommas(12500)],
 ];
 
 const datosInventarioChart = [
     ['Mes', 'Inventario Total', { role: 'annotation' }, 'Mas de 120 dias', { role: 'annotation' }],
     ['Ene', 120000, '120,000', 45000, '45,000'],
     ['Feb', 135000, '135,000', 56000, '56,000'],
     ['Mar', 145000, '145,000', 60000, '60,000'],
     ['Abr', 155000, '155,000', 67000, '67,000'],
     ['May', 170000, '170,000', 72000, '72,000'],
     ['Jun', 185000, '185,000', 80000, '80,000'],
     ['Jul', 195000, '195,000', 85000, '85,000'],
     ['Ago', 210000, '210,000', 92000, '92,000'],
     ['Sep', 225000, '225,000', 98000, '98,000'],
     ['Oct', 240000, '240,000', 105000, '105,000'],
     ['Nov', 255000, '255,000', 112000, '112,000'],
     ['Hoy', 270000, '270,000', 120000, '120,000']
 ];
 
 
const WeeklyDirectionReport = () => {

    const [filters, setFilters] = useState<Filters>(DEFAULT_FILTERS)

     useEffect(()=>{
          document.title = "Resumen Semanal AVA GVE"
     },[])
     const handleClick = () => {
          window.print();
     };

     return (
          <main>
               <div className="print-page">
                    <header id='headerReportDir'>
                         <section className='sectionTitleDate'>
                              <span id='reportTitle'>REPORTE AGROEQUIPOS DEL VALLE</span>
                              <span id='spanDate'>20 MAYO 2024</span>
                         </section>
                         <section className='sectionLogoAG'>
                              <img src={LOGO_AG} width={100}/>
                         </section>
                    </header>
                    <div id='reportContent'>
                         <ResultTableMaquinary title='Resultados de Maquinaria' data={data1} filters={filters}/>
                         <ResultTablePosventa title='Resultados de Posventa' data={data2} filters={filters}/>

                         <ActivesQualityWallet title='Calidad de activos en Cartera 2024' data={datosCartera} filters={filters}/>
                         <ActivesQualityInventory title='Calidad de activos en Inventario 2024' data={datosInventario}/>

                         <TendencyWallet title='Tendencia de Cartera' data={datosCarteraChart}/>
                         <TendencyInventory title='Tendencia de Inventario' data={datosInventarioChart}/>

                         <section id="indicatorAndHighlights">
                              <JohnDeereIndicators/>
                              <Highlights/>
                         </section>
                         <section id="indicatorsInventoryFinancial">
                              <InventoryUnits/>
                              <FinancialIndicators/>
                         </section>
                    </div>
               </div>
               <button className="no-print" onClick={handleClick}>
                    Imprimir
               </button>
          </main>
     );
};

export default WeeklyDirectionReport;
