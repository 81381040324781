
import { useEffect, useState } from 'react';
import { customizeMultiselect } from '../utilities';
import { Dropdown, Panel, Checkbox, MultiSelect } from 'primereact'

import { MONTH_OPTIONS, SUCURSAL_OPTIONS } from '../../../../constants/constants.ts';
import { DepartmentsAndSellers } from '../requests';

const Filters = ({ filters, setFilters }) => {

    const [departments, setDepartments] = useState(null);
    const [sellers, setSellers] = useState(null);

    useEffect(() => {
        const initialize = async () => {
            if (filters.branch !== null) {
                const { SELLERS, DEPARTMENTS } = await DepartmentsAndSellers(filters.branch === null ? -1 : filters.branch);
                setSellers(SELLERS)
                setDepartments(DEPARTMENTS)
            }
        }
        initialize();
        customizeMultiselect()
    }, [filters]);


    return (
        <Panel header='Opciones de Filtrado' className='panel'>
            <div className='panel-content' >
                <span className="p-float-label" style={{height: '100%'}}>
                    <Dropdown
                        inputId="dd-sucursal"
                        value={filters.branch}
                        options={SUCURSAL_OPTIONS}
                        onChange={(e) => setFilters({ ...filters, branch: e.value })}
                        placeholder="Sucursal"
                        className="dropdown"
                        style={{height: '100%', display: 'flex', alignItems: 'center'}}
                    />

                    <label htmlFor="dd-sucursal">Sucursal</label>
                </span>
                <span className="p-float-label mt-20 panel-element">
                    <MultiSelect
                        inputId="dd-departmets"
                        value={filters.departments}
                        options={departments}
                        onChange={(e) => setFilters({ ...filters, departments: e.value })}
                        placeholder="Departamento"
                        className="dropdown"
                        maxSelectedLabels={1}
                        selectedItemsLabel="{0} Departamentos"


                    />

                    <label htmlFor="dd-departments">Departamento</label>
                </span>
                <span className="p-float-label mt-20">
                    <Dropdown
                        inputId="dd-month"
                        value={filters.month}
                        options={MONTH_OPTIONS}
                        onChange={(e) => setFilters({ ...filters, month: e.value })}
                        placeholder="Mes"
                        className="dropdown"

                    />

                    <label htmlFor="dd-month">Mes</label>
                </span>
                <div className="flex align-items-center">
                    <Checkbox
                        className='p-button-primary'
                        inputId="is_accumulated"
                        checked={filters.isAccumulated}
                        onChange={(e) => setFilters({ ...filters, isAccumulated: e.checked })}
                    />
                    <label htmlFor="is_accumulated" className="ml-2">Mostrar acumulado</label>
                </div>
                <span className="p-float-label mt-20 panel-element">
                    <MultiSelect
                        inputId="dd-sucursal"
                        value={filters.sellers}
                        optionLabel="label"
                        optionGroupLabel="label" 
                        optionGroupChildren="items"
                        onChange={(e) => setFilters({ ...filters, sellers: e.value })}
                        options={sellers}
                        placeholder="Colaboradores"
                        className="dropdown"
                        display="chip"
                        scrollHeight='500px'
                        // optionGroupTemplate={(props)=><>{props.code}</>}
                        // selectAll={true}
                    />
                    <label htmlFor="dd-sucursal">Colaboradores</label>
                </span>
            </div>
        </Panel>
    )
}

export default Filters