import React, { useState, useRef, useEffect } from 'react';
// import { Button } from 'primereact/button';
import { Galleria } from 'primereact/galleria';
import Actions from './Actions';
import { TreePin } from '../../types';

interface GalleryProps {
    folderId: number;
    isAdditional: boolean;
    pin: TreePin;
}

const EvidencesGalleria: React.FC<GalleryProps> = ({ folderId, isAdditional, pin }) => {
    const [images, setImages] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const galleria = useRef<Galleria>(null);

    useEffect(() => {
        if (images.length > 0) {
            setTimeout(() => {
                galleria.current?.show();
            }, 500);
        }
    }, [images]);

    useEffect(() => {
        if (folderId) {
            handleFolderIdChange();
        }
    }, [folderId]);

    const fetchImages = async () => {
        setLoading(true);
        try {
            const response = await fetch(`/trees/plantedtrees/gttrmg?folderId=${folderId}&isAdditional=${isAdditional}`);
            if (response.ok) {
                const imageData = await response.json();
                setImages(imageData);
            } else {
                console.error('Failed to fetch images:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching images:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleFolderIdChange = async () => {
        await fetchImages();
        // clean();
    };

    const itemTemplate = (item: string) => (
        <img src={`data:image/jpeg;base64,${item}`} alt="Gallery Item" style={{ width: '25vw', height: '47vh', objectFit: 'cover' }} />
    );

    const thumbnailTemplate = (item: string) => (
        <img src={`data:image/jpeg;base64,${item}`} alt="Gallery Thumbnail" style={{ height: 60, width: 60, objectFit: 'cover' }} />
    );

    const responsiveOptions = [
        { breakpoint: '1500px', numVisible: 5 },
        { breakpoint: '1024px', numVisible: 3 },
        { breakpoint: '768px', numVisible: 2 },
        { breakpoint: '560px', numVisible: 1 }
    ];

    return (
        <div className="">
            {/* <span>{images.length}</span> */}
            {loading ? (
                <div className="loindicatorading">
                    <strong>Cargando las imagenes...</strong>
                </div>
            ) : (
                <div>
                    <Actions pin={pin} refreshPhotos={fetchImages}/>
                    {images.length == 0 ? (
                        <div className="no-planteds-tree">
                            <strong>Esta plantación no tiene evidencias.</strong>
                        </div>
                    ):(

                        <Galleria
                            ref={galleria}
                            value={images}
                            responsiveOptions={responsiveOptions}
                            numVisible={7}
                            style={{ backgroundColor: 'black', padding: '1rem' }}
                            item={itemTemplate}
                            showItemNavigators
                            thumbnail={thumbnailTemplate}
                            circular
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default EvidencesGalleria;
