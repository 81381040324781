import React from 'react'
import { ICON_1, ICON_2, ICON_3, ICON_4, ICON_5 } from '../../../../assets';

const Highlights = () => {

     type PointProp = {
          icon: string;
          number: number;
          text: string;
     }
     const Point:React.FC<PointProp> = ({icon, number, text}) => {
          return (
               <div id='pointHighlights'>
                    <img src={icon} width={'20px'} height={'20px'}/>
                    <label className='font-bold' style={{fontSize: '9px', paddingLeft:'5px'}}>{number}. <span className='font-normal'>{text}</span></label>
               </div>
          )
     }

     return (
          <section className="grid-item" style={{paddingTop: '15px'}}>
               {/* <span id='tableResultTitle'>Highlights</span> */}
               <Point 
                    icon={ICON_1} 
                    number={1} 
                    text='Analisis y de definición de Dealer Capability Exptectations.' 
               />
               <Point 
                    icon={ICON_2} 
                    number={2} 
                    text='Seguimiento al tema de Gobierno Corporativo de manera interna y con John Deere.' 
               />
               <Point 
                    icon={ICON_3} 
                    number={3} 
                    text=' El flujo recuperado al 18 de mayo 36.1 millones en 2024 vs 35.6 millones del 2023.' 
               />
               <Point 
                    icon={ICON_4} 
                    number={4} 
                    text='El objetivo del mes de mayo es de 3.1 milones de pesos, consideramos lograr el presupuesto.' 
               />
               <Point 
                    icon={ICON_5} 
                    number={5} 
                    text='Tuvimos la Expotianguis John Deere durante la semana del 13 al 18 de mayo.' 
               />
          </section>
     )
}

export default Highlights