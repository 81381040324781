import React, { useEffect, useRef, useState } from 'react';
import { FileUpload, ItemTemplateOptions } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import { cancelOptions, chooseOptions, uploadOptions } from '../../../../../constants/constants';

type Props = {
    files: File[]
    setFiles: (files: File[] | undefined) => void
    hideModal: ()=>void
}

export const FileUploadComponent: React.FC<Props> = ({ files, setFiles, hideModal }) => {

    const fileUpload = useRef<FileUpload>(null);
    const [ownFiles, setOwnFiles] = useState<File[]>()

    useEffect(() => {
        setOwnFiles(files)
        fileUpload.current?.setFiles(files)
    }, [])


    const headerTemplate = (options: any) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        return (
            <div 
            className={className} 
            style={{ 
                backgroundColor: 'transparent', 
                display: 'flex', 
                alignItems: 'center' }}
                >
                {chooseButton}
                {uploadButton}
                {cancelButton}
            </div>
        );
    };
    const itemListTemplate = (inFile: object, props: ItemTemplateOptions) => {
        const file = inFile as File;
        return (
            <div className="flex align-items-center flex-wrap ">
                <div className="flex align-items-center" style={{ width: '90%' }}>
                    <img alt={file.name} 
                        role="presentation" 
                        // @ts-ignore
                        src={file.objectURL} 
                        width={100} />
                    <span className="flex flex-column text-left ml-5">
                        {file.name}
                    </span>
                </div>
                <Button 
                    type="button" 
                    icon="pi pi-times" 
                    className="p-button-outlined p-button-rounded p-button-danger ml-auto" 
                    onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };
    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    Arrastra y suelta tus imagenes aquí.
                </span>
            </div>
        );
    };

    const onBeforeUpload = async (e:any) => {
        try {
            // await uploadFiles(ownFiles, 'elimi3');
            // toast.current?.show({ severity: 'info', summary: 'Listo', detail: 'Fotos guardadas con éxito.' });
            // console.log('files desde el componente: ', ownFiles)
            setFiles(ownFiles);
            hideModal();
        } catch (error) {
            console.error('Error uploading images:', error);
            e.reject(); 
        } 
    };
    const onTemplateRemove = (file: File, callback: Function) => {

        const leftFiles = ownFiles?.filter( element => element.name !== file.name)
        setOwnFiles(leftFiles)

        callback();
    };
    
    return (
        <div>
            {/* <Toast ref={toast}></Toast> */}
            <FileUpload 
                multiple 
                ref={fileUpload}
                url='/trees/plantedtrees/svplntdtrs/-1/false'
                accept="image/*" 
                maxFileSize={104857600}
                onSelect={(e)=>{setOwnFiles(e.files)}} 
                onBeforeSend={onBeforeUpload}
                headerTemplate={headerTemplate} 
                itemTemplate={itemListTemplate} 
                emptyTemplate={emptyTemplate}
                chooseOptions={chooseOptions} 
                uploadOptions={uploadOptions} 
                cancelOptions={cancelOptions}
            />
        </div>
    )
}


export default FileUpload