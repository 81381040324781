import React, { useEffect, useState, FC} from 'react'
import { Chart } from 'primereact'
import ExternalTooltipHandler from './CustomToolTip.ts';
import { GetBudgetAndSold } from '../request.ts';
import { Filters, Tractor } from '../../../types.ts';
import { TRACTOR_DETAIL_DATA_DEFAULT } from '../../../defaults.ts';
import { validateFilters } from '../../../../../../helpers/index.ts';

type Props = {
    mainClassName: string;
    filters: Filters
}

type TractorData = {
    amount: number;
    detail: Tractor[];
}

const BudgetAndSold: FC<Props> = ({ mainClassName, filters }) => {

    const [budget, setBudget] = useState<TractorData>(TRACTOR_DETAIL_DATA_DEFAULT)
    const [sold, setSold] = useState<TractorData>(TRACTOR_DETAIL_DATA_DEFAULT)

    const [tooltipDetail, setTooltipDetail] = useState([])

    useEffect(() => {
        const initialize = async () => {
            const response = await GetBudgetAndSold(filters);

            if(!response) return 

            const budgetCount = response.budget.reduce((sum, item) => sum + item.amount, 0);
            const soldCount = response.sold.reduce((sum, item) => sum + item.amount, 0);

            setBudget({
                amount: budgetCount,
                detail: response.budget
            })
            setSold({
                amount: soldCount,
                detail: response.sold
            })

            // @ts-ignore
            setTooltipDetail({
                [`Presupuesto: ${budgetCount}`]: response.budget,
                [`Vendidos: ${soldCount}`]: response.sold
            })
        }
        if(!validateFilters(filters, initialize)){
            setBudget(TRACTOR_DETAIL_DATA_DEFAULT);
            setSold(TRACTOR_DETAIL_DATA_DEFAULT)
        }
    }, [filters])
    
    const chartData = {
        labels: [`Presupuesto: ${budget.amount}`, `Vendidos: ${sold.amount}`],
        datasets: [
            {
                backgroundColor: ['#42A5F5', '#66BB6A'],
                data: [budget.amount, sold.amount],
            },
        ],
    };

    const chartOptions = {   
        responsive: true,
        aspectRatio: 1.3,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
                position: 'average',
                external: (e:any) => { ExternalTooltipHandler(e, tooltipDetail) }
            }
        },
    };


    return (
        <div className={mainClassName}>
            <h6>Venta y Presupuesto de Tractores</h6>
            <Chart 
                data={chartData} 
                type="bar" 
                options={chartOptions} 
                style={{ width: '100%' }} 
            />
        </div>
    )
}

export default BudgetAndSold