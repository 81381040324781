import React, { useState, useEffect, useRef } from 'react';
import '../styles.css';
import { PIN_TREE } from '../../../../assets';
import PinDetail from './PinDetail';
import ReactDOMServer from 'react-dom/server';
import Count from './Count';
import { TreeModuleFilters, TreePin } from '../types';
import ReactDOM from 'react-dom';
import GalleryComponent from './TreeDetail/EvidencesGalleria';
import EvidencesGalleria from './TreeDetail/EvidencesGalleria';
import TreeDetail from './TreeDetail';

const branchConfigurations = {
    "-1": { lat: 25.5667, lng: -108.4667, zoom: 9 }, 
    "1": { lat: 25.7936, lng: -108.9981, zoom: 11 }, 
    "2": { lat: 25.5667, lng: -108.4667, zoom: 10 }, 
    "3": { lat: 25.4500, lng: -108.0700, zoom: 11 } 
};

type Props = {
    filters: TreeModuleFilters;
    treePinList: TreePin[];
    branch: number;
    toPlant: number;
    rerender?: any
}

const Map: React.FC<Props> = ({ filters, treePinList, branch, rerender, toPlant }) => {

    const [map, setMap] = useState<any>(null);
    const [markers, setMarkers] = useState<TreePin[]>([]);
    const [infoWindow, setInfoWindow] = useState<any>(null);
    const [completes, setCompletes] = useState<number>(0)

    const [folderId, setFolderId] = useState<number | null>(null)
    const [isVisibleTreeDetail, setIsVisibleTreeDetail] = useState<boolean>(false)
    const [currentPin, setCurrentPin] = useState<TreePin>()

    const mapDiv2 = useRef<HTMLDivElement | null>(null);

    
    const cleanFolder = () => {
        setFolderId(null)
        setIsVisibleTreeDetail(false)
    }

    const initMap2 = () => {
        
       
        //@ts-ignore
        const branchConfiguration = branchConfigurations[branch.toString()];
    
        //@ts-ignore
        const mapInstance = new window.google.maps.Map(mapDiv2.current!, {
            zoom: branchConfiguration.zoom,
            center: { lat: branchConfiguration.lat, lng: branchConfiguration.lng },
            mapTypeId: 'terrain',
            // styles: customMapStyles
        });
    
        //@ts-ignore
        const infoWindowInstance = new window.google.maps.InfoWindow();
        setInfoWindow(infoWindowInstance);
    
        setMap(mapInstance);
     
    };
    
    
    const getIndications = (markerLat: number, markerLng: number) => {
        navigator.geolocation.getCurrentPosition(function(position) {
            var latitud = position.coords.latitude;
            var longitud = position.coords.longitude;
            var destino = `${markerLat},${markerLng}`
            var url = "https://www.google.com/maps/dir/" + latitud + "," + longitud + "/" + destino;
            window.open(url, '_blank');
        });
    }


    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBYzc3zyKO3bsg1r1OW2gAZV6w_H_nKVOA&callback=initMap`;
        script.defer = true;
        script.async = true;
        // @ts-ignore
        window.initMap = initMap2;
        document.head.appendChild(script);

        return () => {
            // @ts-ignore
            delete window.initMap;
            document.head.removeChild(script);
        };
    }, []);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBYzc3zyKO3bsg1r1OW2gAZV6w_H_nKVOA&callback=initMap`;
        script.defer = true;
        script.async = true;
        // @ts-ignore
        window.initMap = initMap2;
        document.head.appendChild(script);

        return () => {
            // @ts-ignore
            delete window.initMap;
            document.head.removeChild(script);
        };
    }, [rerender]);

    useEffect(() => {
        if (map) {    
            const newMarkers = [...treePinList];

            setMarkers(newMarkers);
            // setCompletes(newMarkers.reduce((acc, pin) => acc + pin.amountPerRecordGroup, 0));
            // setCompletes(newMarkers.length);

            
        }
        
    }, [map, treePinList]);

    useEffect(() => {
        if (map) {
            //@ts-ignore
            const branchConfiguration = branchConfigurations[branch.toString()];
            map.setOptions({
                zoom: branchConfiguration.zoom,
                center: { lat: branchConfiguration.lat, lng: branchConfiguration.lng },
                styles: undefined,
            });
        }
    }, [branch])
    
    useEffect(() => {
            if (map) {
                markers.forEach((marker) => {
                    //@ts-ignore
                    marker.setMap(null);
                });
        
                // Crear nuevos marcadores y agregarlos al estado markers
                const newMarkers = treePinList.map((pin) => {
                    //@ts-ignore
                    const googleMarker = new window.google.maps.Marker({
                        position: { lat: pin.latitude, lng: pin.longitude },
                        map: map,
                        title: pin.customerConcept,
                        icon: {
                            url: PIN_TREE,
                            //@ts-ignore
                            scaledSize: new window.google.maps.Size(50, 50),
                        },
                    });
        
                    // Agregar eventos a los nuevos marcadores
                    googleMarker.addListener('mousedown', () => {
                        if (infoWindow) {
                            const componentString = ReactDOMServer.renderToStaticMarkup(
                                <PinDetail pinData={pin} />
                            );
                            // console.log(componentString)
                            
                            infoWindow.setContent(`<div style="max-width: 500px;">${componentString}</div>`);
                            infoWindow.open(map, googleMarker);
                        }
                    });
        
                    googleMarker.addListener('mouseup', () => {
                        var btnGetDirs = document.querySelector("#btnGetDirs2");
                        if (btnGetDirs) {
                            btnGetDirs.addEventListener('click', () => {
                                getIndications(pin.latitude, pin.longitude);
                            });
                        }

                        //btnSeeImagesFetched
                        var btnGetDirs = document.querySelector("#btnSeeImagesFetched");
                        if (btnGetDirs) {
                            btnGetDirs.addEventListener('click', () => {
                                
                                const folderId = pin.recordId === 0 ? pin.orderId : pin.recordId

                                setCurrentPin(pin)
                                setFolderId(folderId)
                                setIsVisibleTreeDetail(true)
                            });
                        }
                    });
        
                    return googleMarker;
                });
        
                setMarkers(newMarkers);
        
                // También actualiza completos
                setCompletes(treePinList.reduce((acc, pin) => acc + pin.amountPerRecordGroup, 0));
            }
        
    }, [map, treePinList]);
    

  
    return (
        <div style={{ height: '78vh', width: '100%', position: 'relative' }}>
            <div id="map" ref={mapDiv2} className='mapContainer' style={{ height: '78vh', width: '100%' }} />

            {map && ReactDOM.createPortal(
                
                <Count 
                    filters={filters} 
                    completes={completes}
                    remainings={Number(toPlant)}
                    markers={markers}

                />,
                //@ts-ignore
                mapDiv2.current!.parentNode!
            )}
            {( folderId && currentPin ) && (
                <TreeDetail
                    folderId={folderId}
                    isVisible={isVisibleTreeDetail}
                    clean={cleanFolder}
                    pin={currentPin}
                    
                />
            )}
            {/* <GalleryComponent folderId={folderId} clean={cleanFolder} isAdditional={true}/> */}
        </div>
    );
};

export default Map;
