export const customizeMultiselect = () => {
    const multiselectTokenLabels = document.querySelectorAll('.p-multiselect-token-label');

    multiselectTokenLabels.forEach((label) => {
        const palabras = label.textContent.split(' ');
        const ultimaPalabra = palabras[palabras.length - 1];
        label.textContent = ultimaPalabra;
    });
    const multiselectContainer = document.querySelector('.p-multiselect');
    const multiselectLabel = multiselectContainer.querySelector('.p-multiselect-label');

    if (multiselectLabel) {
        multiselectLabel.style.padding = '0.2rem';
        multiselectLabel.style.marginLeft = '0.5rem'

    }

    const chips = document.querySelectorAll('.p-multiselect-token');
    chips.forEach(chip => {
        chip.style.backgroundColor = '#2196F3'
        chip.style.color = '#fff'
        chip.style.height = '22px'
    });

}