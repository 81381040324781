import React from 'react'
import { GET_PIC_OF } from '../../../../constants/constants.ts'

const SellerPicture = ({ sellerId }) => {

    const picRoute = GET_PIC_OF + sellerId
    
    return (
        <div className='card-space img' style={{width: '55rem'}}>
            <img
                src={picRoute}
                style={{
                    objectFit: 'cover',
                    height: '100%',
                    width: '100%'
                }}
                alt='Your Alt Text'
            />
        </div>
    )
}

export default SellerPicture