import React from 'react'
const indicadores = [
     {
         indicador: "Factor de absorción",
         objetivo: "100%",
         avance: "106%"
     },
     {
         indicador: "Satisfacción de clientes",
         objetivo: "90%",
         avance: "98%"
     },
     {
         indicador: "Market Share",
         objetivo: "40%",
         avance: "58%"
     },
     {
         indicador: "México Verde",
         objetivo: 680,
         avance: 706
     },
     {
         indicador: "Capacitación",
         objetivo: "30%",
         avance: "19%"
     },
     {
         indicador: "Reid Controler",
         objetivo: null,
         avance: null
     },
     {
         indicador: "Seed Star",
         objetivo: null,
         avance: null
     },
     {
         indicador: "Hectáreas conectadas",
         objetivo: "31,000",
         avance: "19,781"
     },
     {
         indicador: "Máquinas conectadas",
         objetivo: 56,
         avance: 20
     }
 ];
 
const JohnDeereIndicators = () => {
     return (
          <section className="grid-item">
            <span id='tableResultTitle'>Indicadores John Deere 2024</span>
            <table id='tableResultGCon'>
                <thead>
                    <tr>
                        <th className='text-left tableHeadersStyle'  >Indicador</th>
                        <th className='tableHeadersStyle'>Objetivo</th>
                        <th className='tableHeadersStyle'>Avance</th>
                    </tr>
                </thead>
                <tbody>
                    {indicadores.map((indicador, index) => (
                        <tr key={index}>
                            <td className='text-left'>{indicador.indicador}</td>
                            <td>{indicador.objetivo || '-'}</td>
                            <td>{indicador.avance || '-'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </section>
     )
}

export default JohnDeereIndicators