import React from 'react';
import { Chart } from 'react-google-charts';

type Props = {
    title: string;
    data: any;
}

const TendencyInventory: React.FC<Props> = ({ title, data }) => {

   
    return (
        <section className="grid-item">
            <span id='tableResultTitle'>{title}</span>
            <Chart
                width={'100%'}
                height={'100px'}
                chartType="LineChart"
                data={data}
                options={{
                    chartArea: { width: '85%', height: '70%'},
                    
                    vAxis: {
                        title: 'Miles de Pesos',
                        titleTextStyle: {
                            fontSize: 7 
                        },
                        ticks: [0, 50000,100000,150000,200000,250000,300000],
                        textStyle: {
                            fontSize: 6,
                            fontWeight: 'bold' 
                        },
                        gridlines: {
                           color: 'transparent' 
                       }
                    },
                    hAxis:{
                        textStyle: {
                            fontSize: 7,
                            fontWeight: 'bold' 
                        }
                    },
                    legend: {
                        position: 'top',
                        alignment: 'center',
                        textStyle: {
                            fontSize: 7,
                            fontWeight: 'bold' 
                        }
                    },
                    tooltip: {
                        isHtml: true,
                        content: 'both',
                    },
                    colors: ['green', 'red'],
                    annotations: {
                        textStyle: {
                            fontSize: 6,
                            fontWeight: 'bold',
                            format: 'decimal'
                        },
                        stem: {
                            color: 'none',
                            length: 2
                        },
                        alwaysOutside: true 
                    },
                }}
            />
        </section>
    );
}

export default TendencyInventory;
