const getOrCreateTooltip = (chart: any) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');

    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
        tooltipEl.style.borderRadius = '3px';
        tooltipEl.style.color = 'white';
        tooltipEl.style.opacity = 1;
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.transform = 'translate(-50%, 0)';
        tooltipEl.style.transition = 'all .1s ease';
        tooltipEl.style.width = '50wh';

        const table = document.createElement('table');
        table.style.margin = '0px';

        tooltipEl.appendChild(table);
        chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
};

const ExternalTooltipHandler = (context: any, tooltipDetail: any, removePercent?: boolean) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
    }
    
    // Set Text
    if (tooltip.body) {
        const tableHead = document.createElement('thead');
        const headerRow = document.createElement('tr'); // Mover aquí para asegurar que esté al principio
        const titleLines = tooltip.title || [];
        const data = tooltipDetail[titleLines];

        // Crear encabezado
        titleLines.forEach((title: string) => {
            const th = document.createElement('th');
            th.style.borderWidth = '0';
            const text = document.createTextNode(removePercent ? title.slice(7) : title);
            th.appendChild(text);
            headerRow.appendChild(th); // Agregar directamente al headerRow
        });
        tableHead.appendChild(headerRow); // Agregar el headerRow al tableHead

        const tableBody = document.createElement('tbody');

        // Crear filas con datos
        if (data && data.length > 0) {
            for (let key in data[0]) {
                const th = document.createElement('th');
                th.appendChild(document.createTextNode(key === 'model' ? 'Modelo' : key === 'amount' ? 'Cantidad' : key));
                tableHead.appendChild(th);
            }

            data.forEach((obj: any) => {
                const tr = document.createElement('tr');
                let isFirstColumn = true; // Variable para identificar la primera columna
                for (const key in obj) {
                    const td = document.createElement('td');

                    // Ajusta el ancho de la primera columna
                    if (isFirstColumn) {
                        td.style.minWidth = '13rem'; // Ajusta a un tamaño mayor
                        isFirstColumn = false;
                    } else {
                        td.style.minWidth = '5rem';
                    }

                    td.appendChild(document.createTextNode(obj[key]));
                    tr.appendChild(td);
                }
                tr.style.borderBottom = '1px solid gray';
                tableBody.appendChild(tr);
            });
        }

        const tableRoot = tooltipEl.querySelector('table');

        // Remove old children
        while (tableRoot.firstChild) {
            tableRoot.firstChild.remove();
        }

        // Add new children
        tableRoot.style.fontSize = '80%';
        tableRoot.appendChild(tableHead);
        tableRoot.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.zIndex = 2;
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX * 2 + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};

export default ExternalTooltipHandler;
