import React, { useEffect, useState } from 'react';
import { Calendar } from 'primereact';
import { Dropdown } from 'primereact/dropdown';

type DateRange = {
     from: null | Date,
     to: null | Date,
};

type RangeOption = {
     id: string,
     label: string,
};

type Props = {
     onRangeChange: (param: DateRange | null) => void,
};

const DatePickerRange: React.FC<Props> = ({ onRangeChange }) => {
     const [date, setDate] = useState<DateRange>({
          from: null,
          to: null,
     });
     const [currentRangeLabel, setCurrentRangeLabel] = useState<string | null>(null)

     const rangeOptions: RangeOption[] = [
          { id: 'ABIERTO', label: 'ABIERTO' },
          { id: 'Hoy', label: 'Hoy' },
          { id: 'Ayer', label: 'Ayer' },
          { id: 'SemanaActual', label: 'Semana Actual' },
          { id: 'SemanaPasada', label: 'Semana Pasada' },
          { id: 'SemanaFecha', label: 'Semana a la Fecha' },
          { id: 'PrimeraQuincena', label: '1ra. Quincena' },
          { id: 'SegundaQuincena', label: '2da. Quincena' },
          { id: 'QuincenaActual', label: 'Quincena Actual' },
          { id: 'QuincenaPasada', label: 'Quincena Pasada' },
          { id: 'MesActual', label: 'Mes Actual' },
          { id: 'MesPasado', label: 'Mes Pasado' },
          { id: 'MesFecha', label: 'Mes a la Fecha' },
          { id: 'Ultimos30Dias', label: 'Ultimos 30 días' },
          { id: 'Ultimos3Meses', label: 'Ultimos 3 meses' },
          { id: 'Ultimos6Meses', label: 'Ultimos 6 meses' },
          { id: 'Ultimos12Meses', label: 'Ultimos 12 meses' },
          { id: 'AnoActual', label: 'Año actual' },
          { id: 'AnoPasado', label: 'Año Pasado' },
          { id: 'AnoFecha', label: 'Año a la Fecha' },
          { id: 'AnoJohnDeere', label: 'Año \'John Deere\'' },
          { id: 'AnoPasadoJohnDeere', label: 'Año Pasado \'John Deere\'' },
     ];

     const handleSelectChange = (selectedRangeId: string) => {
          setCurrentRangeLabel(selectedRangeId)

          const today = new Date();
          today.setHours(0, 0, 0, 0);

          const yesterday = new Date(today);
          yesterday.setDate(today.getDate() - 1);
          yesterday.setHours(0, 0, 0, 0);


          switch (selectedRangeId) {
               case 'ABIERTO':
                    setDate({ from: null, to: null });
                    break;
               case 'Hoy':
                    setDate({ from: today, to: today });
                    break;
               case 'Ayer':
                    setDate({ from: yesterday, to: yesterday });
                    break;
               case 'Ultimos30Dias':
                    const thirtyDaysAgo = new Date(today);
                    thirtyDaysAgo.setDate(today.getDate() - 30);
                    setDate({ from: thirtyDaysAgo, to: today });
                    break;
               case 'SemanaActual':
                    const firstDayOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
                    const lastDayOfWeek = new Date(today.setDate(today.getDate() + (6 - today.getDay())));
                    setDate({ from: firstDayOfWeek, to: lastDayOfWeek });
                    break;
               case 'SemanaPasada':
                    const lastSunday = new Date(today);
                    lastSunday.setDate(today.getDate() - today.getDay() - 7);
                    const lastSaturday = new Date(lastSunday);
                    lastSaturday.setDate(lastSunday.getDate() + 6);
                    setDate({ from: lastSunday, to: lastSaturday });
                    break;
               case 'SemanaFecha':
                    const currentSunday = new Date(today);
                    currentSunday.setDate(today.getDate() - today.getDay());
                    const currentSaturday = new Date(currentSunday);
                    currentSaturday.setDate(currentSunday.getDate() + 6);
                    setDate({ from: currentSunday, to: currentSaturday });
                    break;
               case 'PrimeraQuincena':
                    setDate({ from: new Date(today.getFullYear(), today.getMonth(), 1), to: new Date(today.getFullYear(), today.getMonth(), 15) });
                    break;
               case 'SegundaQuincena':
                    setDate({ from: new Date(today.getFullYear(), today.getMonth(), 16), to: new Date(today.getFullYear(), today.getMonth() + 1, 0) });
                    break;
               case 'QuincenaActual':
                    if (today.getDate() <= 15) {
                         setDate({ from: new Date(today.getFullYear(), today.getMonth(), 1), to: new Date(today.getFullYear(), today.getMonth(), 15) });
                    } else {
                         setDate({ from: new Date(today.getFullYear(), today.getMonth(), 16), to: new Date(today.getFullYear(), today.getMonth() + 1, 0) });
                    }
                    break;
               case 'QuincenaPasada':
                    let startOfLastFortnight;
                    let endOfLastFortnight;
                    if (today.getDate() <= 15) {
                         startOfLastFortnight = new Date(today.getFullYear(), today.getMonth() - 1, 16);
                         endOfLastFortnight = new Date(today.getFullYear(), today.getMonth(), 0);
                    } else {
                         startOfLastFortnight = new Date(today.getFullYear(), today.getMonth(), 1);
                         endOfLastFortnight = new Date(today.getFullYear(), today.getMonth(), 15);
                    }
                    setDate({ from: startOfLastFortnight, to: endOfLastFortnight });
                    break;
               case 'MesActual':
                    const startOfMontha = new Date(today.getFullYear(), today.getMonth(), 1);
                    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                    setDate({ from: startOfMontha, to: endOfMonth });
                    break;
               case 'MesPasado':
                    setDate({
                         from: new Date(today.getFullYear(), today.getMonth() - 1, 1),
                         to: new Date(today.getFullYear(), today.getMonth(), 0),
                    });
                    break;
               case 'MesFecha':
                    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
                    setDate({ from: startOfMonth, to: today });
                    break;
               case 'Ultimos3Meses':
                    const threeMonthsAgo = new Date(today);
                    threeMonthsAgo.setMonth(today.getMonth() - 3);
                    setDate({ from: threeMonthsAgo, to: today });
                    break;
               case 'Ultimos6Meses':
                    const sixMonthsAgo = new Date(today);
                    sixMonthsAgo.setMonth(today.getMonth() - 6);
                    setDate({ from: sixMonthsAgo, to: today });
                    break;
               case 'Ultimos12Meses':
                    const twelveMonthsAgo = new Date(today);
                    twelveMonthsAgo.setFullYear(today.getFullYear() - 1);
                    setDate({ from: twelveMonthsAgo, to: today });
                    break;
               case 'AnoActual':
                    const startOfYear = new Date(today.getFullYear(), 0, 1);
                    const endOfYear = new Date(today.getFullYear() + 1, 0, 0);
                    setDate({ from: startOfYear, to: endOfYear });
                    break;
               case 'AnoPasado':
                    setDate({ from: new Date(today.getFullYear() - 1, 0, 1), to: new Date(today.getFullYear() - 1, 11, 31) });
                    break;
               case 'AnoFecha':
                    setDate({ from: new Date(today.getFullYear(), 0, 1), to: today });
                    break;
               case 'AnoJohnDeere':
                    setDate({ from: new Date(today.getFullYear() - 1, 9, 31), to: new Date(today.getFullYear(), 10, 1) });
                    break;
               case 'AnoPasadoJohnDeere':
                    setDate({ from: new Date(today.getFullYear() - 2, 9, 31), to: new Date(today.getFullYear() -1 , 10, 1) });
                    break;
               default:
                    break;
          }
     };

     useEffect(() => {
          onRangeChange(date);
     }, [date]);

     return (
          <div style={{display: 'flex',flexDirection: 'column',gap:'2rem'}} >
               <span className="p-float-label panel-element">
                    <Dropdown 
                         options={rangeOptions} 
                         onChange={(e) => {handleSelectChange(e.value.id as string); setCurrentRangeLabel(e.value)}} 
                         optionLabel="label" 
                         value={currentRangeLabel}
                         placeholder="Seleccione un rango"
                         style={{  }}
                         className="dropdown"
                         scrollHeight='500px'
                    />
                    <label htmlFor="dd-sucursal">Rango de Fechas</label>
               </span>
                
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <span className="p-float-label">
                            <Calendar
                                inputId="from"
                                value={date.from}
                                onChange={(e: any) => setDate({ ...date, from: e.value })}
                                style={{ height: '30px', width: '6.5rem', padding: 0 }}
                                dateFormat="dd/mm/yy"
                            />
                            <label htmlFor="from">Desde</label>
                    </span>
                    <span> - </span>
                    <span className="p-float-label">
                            <Calendar
                                inputId="to"
                                value={date.to}
                                onChange={(e: any) => setDate({ ...date, to: e.value })}
                                style={{ height: '30px', width: '6.5rem', padding: 0, textAlign: 'center' }}
                                dateFormat="dd/mm/yy"
                            />
                            <label htmlFor="to" style={{ textAlign: 'center' }}>Hasta</label>
                    </span>
                </div>
          </div>
     );
};

export default DatePickerRange;
