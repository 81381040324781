import React from 'react';
import './SeguimientoSurtido.css';
import {
    Tag,
    Panel,
    Chart,
    Column,
    Dropdown,
    DataTable,
    InputText
} from 'primereact';
import useSeguimientoSurtido from './useSeguimientoSurtido';
import useConnection from '../../../general_hooks/useConnection';
import { SUCURSAL_OPTIONS, ESTATUS_OPTIONS, CHART_OPTIONS, PEDIDO_OPTIONS, STACKEDBAR_OPTIONS } from '../../../constants/constants.ts';
import { TableHeader } from '../../../general_components/TableHeader'

const SeguimientoSurtido = () => {
    const { backend } = useConnection();
    const {
        timelineChartData,
        tableData,
        getSeverity,
        setTableData,
        actualizarPiezaSurtida,
        getDescription,
        getId,
        getEstatues,
        handleFilterChangeAndFetchData,
        filters,
        stackedData,
        lastPercent
    } = useSeguimientoSurtido(backend);

    const StatusEditor = (options) => {
        return (
            <Dropdown
                value={options.value}
                options={getEstatues()}
                onChange={(e) => options.editorCallback(e.value)}
                placeholder="Select a Status"
                itemTemplate={(option) => {
                    return <Tag value={option} severity={getSeverity(getId(option))}></Tag>;
                }}
            />
        );
    };
    const CommentEditor = (options) => {
        return <InputText style={{ width: '100%' }} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };
    const StatusTemplate = (rowData) => {
        const description = typeof (rowData.estatus) == 'string' ? getDescription(getId(rowData.estatus)) : getDescription(rowData.estatus)
        const estatus = typeof (rowData.estatus) == 'string' ? getSeverity(getId(rowData.estatus)) : getSeverity(rowData.estatus)

        return <Tag value={description} severity={estatus}></Tag>;
    };
    const OnRowEditComplete = (e) => {
        let _products = [...tableData];
        let { newData, index } = e;

        _products[index] = newData;

        const itemID = typeof (_products[index].estatus) == 'string' ? getId(_products[index].estatus) : _products[index].estatus;
        actualizarPiezaSurtida(_products[index].id, itemID, _products[index].comentario);

        setTableData(_products);
    };

    return (
        <main className="seguimiento-surtido-container">
            <section className="filters-container">
                <Panel header="Opciones de Filtrado" className='panelAS' >
                    <div className="filter-column">

                        <span className="p-float-label mt-10">
                            <Dropdown
                                inputId="dd-sucursal"
                                value={filters.sucursal}
                                options={SUCURSAL_OPTIONS}
                                onChange={(e) => handleFilterChangeAndFetchData(e.value,'sucursal')}
                                placeholder="Sucursal"
                                className="w-full md:w-14rem"

                            />
                            <label htmlFor="dd-sucursal">Sucursal</label>
                        </span>

                        <span className="p-float-label">
                            <Dropdown
                                inputId="dd-estatus"
                                value={filters.status}
                                options={ESTATUS_OPTIONS}
                                onChange={(e) => handleFilterChangeAndFetchData(e.value, 'estatus')}
                                placeholder="Estatus"
                                className="w-full md:w-14rem"
                            />
                            <label htmlFor="dd-estatus">Estatus</label>
                        </span>

                        <span className="p-float-label">
                            <Dropdown
                                inputId="dd-tipo-pedido"
                                value={filters.pedido}
                                options={PEDIDO_OPTIONS}
                                onChange={(e) => handleFilterChangeAndFetchData(e.value, 'pedido')}
                                placeholder="Tipo de Pedido"
                                className="w-full md:w-14rem"
                            />
                            <label htmlFor="dd-tipo-pedido">Tipo de Pedido</label>
                        </span>

                    </div>

                </Panel>
                <Panel header="Historial de importacion" className='panelChart'>
                    <div className="chart-container">
                        {timelineChartData &&
                            <Chart
                                type="line"
                                data={timelineChartData}
                                options={CHART_OPTIONS}
                            />
                        }
                    </div>
                </Panel>
                <Panel header="Porcentaje Surtido Actual" className='panelAS' >
                   <div style={{display: 'grid', placeContent: 'center', height: '100%'}}>
                    
                   <span style={{ fontSize: '3.5rem', color: lastPercent >= 85 ? '#367c2b' : 'red', fontWeight: 'bold' }}>
                        {lastPercent}%
                    </span>

                    
                    </div>
                </Panel>
            </section>
            <Chart type="bar" data={
                {
                    labels: ['Surtido'],
                    datasets: [
                        {
                            label: 'No Asignados',
                            data: [stackedData.na],
                            backgroundColor: '#FBC02D',
                        },
                        {
                            label: 'Pedidos',
                            data: [stackedData.pedidos],
                            backgroundColor: '#22C55E',
                        },
                        {
                            label: 'Omitidos',
                            data: [stackedData.omitidos],
                            backgroundColor: '#3B82F6',
                        },
                        {
                            label: 'Por Caducar',
                            data: [stackedData.pc],
                            backgroundColor: '#EF4444',
                        },
                    ],
                }
            }
                options={STACKEDBAR_OPTIONS} style={{ height: '6rem' }} />
            <section className="table-container">
                <DataTable
                    value={tableData}
                    size="small"
                    header={TableHeader("Piezas surtidas",tableData)}
                    paginator
                    scrollable
                    rows={10}
                    selectionMode="single"
                    rowsPerPageOptions={[5, 10, 25, 50, -1]}
                    tableStyle={{ minWidth: '50rem' }}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords}"
                    showGridlines
                    editMode="row"
                    onRowEditComplete={OnRowEditComplete}
                >
                    <Column field="index" header="#" sortable />
                    <Column field="numerO_PARTE" header="Numero de Parte" sortable bodyClassName="p-text-center" />
                    <Column field="descripcion" header="Descripcion" sortable />
                    <Column field="ubicacion" header="Ubicacion" sortable />
                    <Column field="costo" header="Costo" sortable />
                    <Column field="estatus" header="Estatus" body={StatusTemplate} editor={(options) => StatusEditor(options)} sortable />
                    <Column field="comentario" header="Comentario" sortable editor={(options) => CommentEditor(options)} style={{ width: '20%' }} />
                    <Column header="Editar" rowEditor headerStyle={{ width: '7rem', minWidth: '7rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                </DataTable>
            </section>
        </main>
    );
};

export default SeguimientoSurtido;
