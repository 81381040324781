import axios from 'axios'
import { Filters, MarketShareT, Tractor } from '../../types';
import { BUDGET_SOLD, MARKET_SHARE_DEFAULT_DATA, TRACTOR_DETAIL_DATA_DEFAULT } from '../../defaults';

const SOURCE = '/backend/dashboardventas';
const BS_TRACTORS = '/rqstbs'
const MARKET_SHARE = '/gtmrktshr'
const SALES_LEADERS = '/gtslsldrs'
const PROSPECTING_BY_STATUS = '/gtprspctngbstts'

const PROSPECTING_BY_EQUIP = '/gtprspctngbqp'

const PROSPECTING_BY_PROB = '/gtprspctngbprbblt'


type BudgetAndSold = {
     budget: Tractor[],
     sold: Tractor[]
}



export const GetBudgetAndSold = async (filters: Filters): Promise<BudgetAndSold>  => {

     let dataHandled: BudgetAndSold = BUDGET_SOLD;

     const { employees, from, to } = filters
     
     try {
          const response = await axios.get(`${SOURCE}${BS_TRACTORS}`,{
               params: {
                    sellers: employees,
                    from,
                    to
               }
          })

          if (!response) throw new Error('Sin Datos')

          for (const key in response.data) {
               // @ts-ignore
               dataHandled[key] = JSON.parse(response.data[key]);

               // @ts-ignore
               dataHandled[key] = dataHandled[key].map(item => {
                    return {
                         model: item.model,
                         amount: item.amount
                    };
               }) 
          }
     } catch (error) {
          // AQUI SE MANEJARAN LOS ERRORES POSTERIORMENTE
          console.error(error)
     }
     finally{
          return dataHandled
     }
}

export const GetMarketShare = async (filters: Filters):Promise<MarketShareT>  => {

     // declaracion y asignacion de valor default
     let dataHandled2: MarketShareT = MARKET_SHARE_DEFAULT_DATA;

     // console.log(filters)

     const { branch, from, to } = filters;

     try {
          const response = await axios.get(`${SOURCE}${MARKET_SHARE}`,{
               params: {
                    branch,
                    from,
                    to
               }
          })

          if (!response) throw new Error('Sin Datos')

          if (response.data == 'EMPTY') throw new Error('Sin Datos')

          dataHandled2.brands = response.data.map((item: any) => item.sNombre);
          dataHandled2.amounts = response.data.map((item: any) => item.nCantidad);
          dataHandled2.colors = response.data.map((item: any) => item.sColor);

          // response.data.forEach((element: any) => {
          //      dataHandled.brands.push(element.sNombre);
          //      dataHandled.amounts.push(element.nCantidad);
          //      dataHandled.colors.push(element.sColor);
          // });

     } catch (error) {
          // AQUI SE MANEJARAN LOS ERRORES POSTERIORMENTE
          return MARKET_SHARE_DEFAULT_DATA
     }
     finally{
          // Dos arreglos
          // 1 - Strings con los nombres
          // 2 - Numbers con las cantidades
          return dataHandled2
     }
}


export const GetMarketShareWithoutType = async (filters: Filters)  => {

     const { branch, from, to } = filters;
     try {
          const response = await axios.get(`${SOURCE}${MARKET_SHARE}`,{
               params: {
                    branch,
                    from,
                    to
               }
          })

          if (!response) throw new Error('Sin Datos')
          if (response.data == 'EMPTY') throw new Error('Sin Datos')

          return response.data
     } catch (error) {
          return null
     }
}


export const GetSalesLeaders = async (filters: Filters)  => {

     const { branch, from, to } = filters;
     try {
          const response = await axios.get(`${SOURCE}${SALES_LEADERS}`,{
               params: {
                    branch,
                    from,
                    to
               }
          })

          console.log('Desde el request: ',response)
          if (!response) throw new Error('Sin Datos')
          if (response.data == 'EMPTY') throw new Error('Sin Datos')

          return response.data
     } catch (error) {
          return null
     }
}

export const GetProspectingByStatus = async (filters: Filters)  => {

     const { branch, from, to, employees } = filters;

     try {
          const response = await axios.get(`${SOURCE}${PROSPECTING_BY_STATUS}`,{
               params: {
                    branch,
                    sellers: employees,
                    from,
                    to,
                    fromToday: false
               }
          })

          if (!response) throw new Error('Sin Datos')
          if (response.data == 'EMPTY') throw new Error('Sin Datos')


          const response2 = await axios.get(`${SOURCE}${PROSPECTING_BY_STATUS}`,{
               params: {
                    branch,
                    sellers: employees,
                    from,
                    to,
                    fromToday: true
               }
          })

          if (!response2) throw new Error('Sin Datos')
          if (response2.data == 'EMPTY') throw new Error('Sin Datos')

          return [response.data, response2.data]
     } catch (error) {
          return null
     }
}


export const GetProspectingByEquip = async (filters: Filters)  => {

     const { branch, from, to, employees } = filters;

     try {
          const response = await axios.get(`${SOURCE}${PROSPECTING_BY_EQUIP}`,{
               params: {
                    branch,
                    sellers: employees,
                    from,
                    to,
                    fromToday: false
               }
          })

          if (!response) throw new Error('Sin Datos')
          if (response.data == 'EMPTY') throw new Error('Sin Datos')


          const response2 = await axios.get(`${SOURCE}${PROSPECTING_BY_EQUIP}`,{
               params: {
                    branch,
                    sellers: employees,
                    from,
                    to,
                    fromToday: true
               }
          })

          if (!response2) throw new Error('Sin Datos')
          if (response2.data == 'EMPTY') throw new Error('Sin Datos')
          

          return [response.data, response2.data]
     } catch (error) {
          return null
     }
}


export const GetProspectingByProbability = async (filters: Filters)  => {

     const { branch, from, to, employees } = filters;


     try {
          const response = await axios.get(`${SOURCE}${PROSPECTING_BY_PROB}`,{
               params: {
                    branch,
                    sellers: employees,
                    from,
                    to,
                    fromToday: false
               }
          })

          if (!response) throw new Error('Sin Datos')
          if (response.data == 'EMPTY') throw new Error('Sin Datos')


          const response2 = await axios.get(`${SOURCE}${PROSPECTING_BY_PROB}`,{
               params: {
                    branch,
                    sellers: employees,
                    from,
                    to,
                    fromToday: true
               }
          })

          if (!response2) throw new Error('Sin Datos')
          if (response2.data == 'EMPTY') throw new Error('Sin Datos')

          return [response.data,response2.data]
     } catch (error) {
          return null
     }
}