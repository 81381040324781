import React, { ReactNode } from 'react';
import '../index.css'

type Props = {
     children: ReactNode;
     title: string; 
     height?: string;
     width?: string;
};

const Card: React.FC<Props> = ({ children, title, height,width }) => {
     return (
          <div className='cardPanel' style={{height: height, width: width}}>
               <div className='cardTitleHeader'>
                    <span className='cardTitleText'>{title}</span>
               </div>
               {children}
          </div>
     );
};

export default Card;
