import React from 'react'

const Separator = () => {
     return (
          <div style={{
               height:1, 
               backgroundColor: 'rgba(0,0,0,0.2)', 
               width:'90%', 
               margin: '0 1rem'
          }}/>
     )
}

export default Separator