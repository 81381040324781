import React, { FC, useEffect, useState } from 'react'
import IndicatorProb from './IndicatorProb'
import '../../../styles/ProspectingProb.css'
import { Filters } from '../../../types';
import { validateFilters } from '../../../../../../helpers';
import { GetProspectingByProbability } from '../request';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
        
type Props = {
    mainClassName: string;
    filters: Filters;
    setToggleProspecting: any;
    toggleProspecting: any
}

type ProspectingProb = {
    id: number;        
    description: string;       
    color: string;  
    amount: number
};

const ProspectingProb: FC<Props> = ({ mainClassName, filters, setToggleProspecting, toggleProspecting}) => {

    const [responses, setResponses] = useState<any>()
    const [prospectingData, setProspectingData] = useState<ProspectingProb[]>([]);


    useEffect(() => {
        const initialize = async () => {
            const responses = await GetProspectingByProbability(filters);

            if (!responses) {
                setResponses([]);
                return;
            } 

            const prospectingDataUpdated = responses[toggleProspecting ? 1 : 0];
            setProspectingData(prospectingDataUpdated)

            setResponses(responses);
        };
        if(!validateFilters(filters, initialize)){
            setResponses([])
            setProspectingData([])
        }
    }, [filters]);

    useEffect(() => {
        if(responses){
            const prospectingDataUpdated = responses[toggleProspecting ? 1 : 0];
            setProspectingData(prospectingDataUpdated)
        }
    }, [toggleProspecting]);

    return (
        <div className={mainClassName}>
            <h6>Prospección por Probabilidad </h6>
            {prospectingData.length > 0 && (
                <div id='toggleProspecting'>
                    <TriStateCheckbox 
                        value={toggleProspecting} 
                        onChange={() => {setToggleProspecting(!toggleProspecting ? true : null)}}
                        tooltip='Mostrar prospectos desde el dia de hoy hasta la fecha definida.'
                        tooltipOptions={{
                            position: 'top'
                        }}
                    />
                    <label id='toggleBtn' className='ml-2'>Solo Vigentes</label>
                </div>
            )}
            <div id='propContainer'>
                {prospectingData && (
                    prospectingData.map((item, index) => (
                        <IndicatorProb
                            key={index}
                            color={item.color}
                            amount={item.amount.toString()}
                            value={item.description}
                        />
                    ))
                )}
            </div>
        </div>
    )
}

export default ProspectingProb