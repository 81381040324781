import React,{FC} from 'react'
import '../../../styles/IndicatorProb.css'

type Props = {
    color: string;
    value: string;
    amount: string;
}
  

const IndicatorProb: FC<Props> = ({ color, value, amount }) => {
    return (
        <div className='indicator-container'>
            <div className='value' style={{ backgroundColor: color }}>
                {value}
            </div>
            <hr className="hrLinea" style={{ borderColor: color }} />
            <div className='amount' style={{ color: color, borderColor: color }} >
                {amount} Prospectos
            </div>
        </div>
    )
}

export default IndicatorProb