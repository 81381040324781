/* eslint-disable react-hooks/exhaustive-deps */
import  { useEffect } from 'react';
import useConnection from '../general_hooks/useConnection';

const Inicio = () => {
    const { production, development } = useConnection();
    useEffect(() => {
        window.location.href = production;
    }, []);

    return null;
};

export default Inicio;
