import React, {FC} from 'react'
import '../../../styles/OpsItem.css'

type Props = {
    department: string;
    ops: string;
    amount: string
}

const OpsItem: FC<Props> = ({ department, ops, amount }) => {
    return (
        <div className='item-content'>
            <span id='dept-name'>{department}</span>
            <span id='ops'>{ops} Ops.</span>
            <span id='total-amount'>{amount}</span>
        </div>
    )
}

export default OpsItem