/* eslint-disable react-hooks/exhaustive-deps */
import './styles.css'
import { useEffect, useState } from "react"
import { GetMainData, GetSummaryData } from "./requests"
import FirstLevel from './components/FirstLevel'
import TopCustomersAndGrossProfit from './components/SecondLevel'
import BudgetAdvancementSection from './components/ThirdLevel'
import { validateFilters } from '../../../helpers'
import Summary from './components/Summary'

const DEFAULT_FILTERS = {
    branch: null,
    sellers: null,
    month: null,
    isAccumulated: false,
    departments: null
}

const BudgetAdvancement = () => {

    const [filters, setFilters] = useState(DEFAULT_FILTERS)
    const [budgetAdvancement, setBudgetAdvancement] = useState(null)
    const [grossProfitLastYear, setGrossProfitLastYear] = useState(null)
    const [marginLastYear, setMarginLastYear] = useState(null)
    const [baCurrentYear, setBaCurrentYear] = useState(null)
    const [summaryData, setSummaryData] = useState(null);
    


    useEffect(() => {

        const fetchAllData = async () => {
            const mainData = await GetMainData(filters);
            setBudgetAdvancement(mainData.BUDGET_ADVANCEMENT)
            setGrossProfitLastYear(mainData.GROSSPROFIT_LASTYEAR)
            setMarginLastYear(mainData.MARGIN_LASTYEAR)
            setBaCurrentYear(mainData.BUDGET_ADVANCEMENT_CURRENT_YEAR)


            const request = await GetSummaryData(filters);
            setSummaryData(request);
        }
        if (!validateFilters(filters, fetchAllData)) {
            setGrossProfitLastYear(null)
            setMarginLastYear(null)
            setBaCurrentYear(null)
            setSummaryData(null)
        }


    }, [filters])

    return (
        <main className="main">
            {/* <div style={{ height: 50, width: 50, backgroundColor: getRandomDarkColor() }} /> */}
            <FirstLevel
                filters={filters}
                setFilters={setFilters}
                dataFetched={budgetAdvancement}
            />
             {
                (grossProfitLastYear && marginLastYear) &&
                <TopCustomersAndGrossProfit
                    grossProfitData={grossProfitLastYear}
                    marginData={marginLastYear}
                />
            }
            {
                baCurrentYear &&
                    <BudgetAdvancementSection
                        filters={filters}
                        data={baCurrentYear}
                    /> 
            }
            {
                summaryData &&
                <Summary summaryData={summaryData}/>
            }

        </main>
    )
}
export default BudgetAdvancement