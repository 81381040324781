export const GET_PIC_OF = 'https://siia.agroequipos.mx/resources/photos/getPhoto?nEmployee='
export const SUCURSAL_OPTIONS = [
    { label: 'Todas las Sucursales', value: -1 },
    { label: 'Los Mochis', value: 1 },
    { label: 'Guasave', value: 2 },
    { label: 'Guamuchil', value: 3 },
];

export const SUCURSAL_OPTIONS_WITHOUT_ALL = [
    { label: 'Los Mochis', value: 1 },
    { label: 'Guasave', value: 2 },
    { label: 'Guamuchil', value: 3 },
];


export const DEPARTMENTS = [
    { label: 'Ventas', value: 1 },
    { label: 'Refacciones', value: 2 },
    { label: 'Servicio', value: 3 },
    { label: 'Administración', value: 4 },
    { label: 'Contabilidad', value: 5 },
    { label: 'Intendencia', value: 6 },
    { label: 'Staff', value: 10 },
    { label: 'Agricultura de Precisión (SAT)', value: 11 },
    { label: 'Nivelación', value: 12 },
    { label: 'Soluciones Integrales', value: 16 },
    { label: 'SISTEMAS', value: 18 },
    { label: 'Sistemas', value: 25 },
    { label: 'Marketing', value: 26 },
    { label: 'OTRO', value: 100 },
];

export const DEPARTMENTS_WITH_ALL = [
    { label: 'Todos los departamentos', value: -1 },
    { label: 'Ventas', value: 1 },
    { label: 'Refacciones', value: 2 },
    { label: 'Servicio', value: 3 },
    { label: 'Administración', value: 4 },
    { label: 'Contabilidad', value: 5 },
    { label: 'Intendencia', value: 6 },
    { label: 'Staff', value: 10 },
    { label: 'Agricultura de Precisión (SAT)', value: 11 },
    { label: 'Nivelación', value: 12 },
    { label: 'Soluciones Integrales', value: 16 },
    { label: 'SISTEMAS', value: 18 },
    { label: 'Sistemas', value: 25 },
    { label: 'Marketing', value: 26 },
    { label: 'OTRO', value: 100 },
];


export const MONTH_OPTIONS = [
    { label: 'Enero', value: '1' },
    { label: 'Febrero', value: '2' },
    { label: 'Marzo', value: '3' },
    { label: 'Abril', value: '4' },
    { label: 'Mayo', value: '5' },
    { label: 'Junio', value: '6' },
    { label: 'Julio', value: '7' },
    { label: 'Agosto', value: '8' },
    { label: 'Septiembre', value: '9' },
    { label: 'Octubre', value: '10' },
    { label: 'Noviembre', value: '11' },
    { label: 'Diciembre', value: '12' }
];

export const SELLERS_REF = [
    { label: 'TODOS', value: '-1' },
    { label: 'Gonzalez Lopez Jose Alfredo', value: '143' },
    { label: 'Cruz Ruiz Gibran Michel', value: '135' },
    { label: 'Calderon Valle Martin', value: '161' },
    { label: 'Monje Parra Arnold Alfredo', value: '225' },
    { label: 'Lugo Gerardo Armando', value: '232' },
];
export const SELLERS_REF2 = [
    { label: 'Gonzalez Lopez Jose Alfredo', value: '143' },
    { label: 'Cruz Ruiz Gibran Michel', value: '135' },
    { label: 'Calderon Valle Martin', value: '161' },
    { label: 'Monje Parra Arnold', value: '225' },
    { label: 'Lugo Gerardo Armando', value: '232' },
];


export const PEDIDO_OPTIONS = [
    { label: 'TODOS', value: '-1' },
    { label: '2+', value: '1' },
    { label: '3+', value: '2' },
];

export const ESTATUS_OPTIONS = [
    { label: 'TODOS', value: '-1' },
    { label: 'No asignado', value: '0' },
    { label: 'Pedido', value: '1' },
    { label: 'Omitido', value: '2' },
    { label: 'Por Caducar', value: '3' },
];

export const CHART_OPTIONS = {
    maintainAspectRatio: false,
    aspectRatio: 1,
};

export const CHART_OPTIONS2 = {
    maintainAspectRatio: false,
    aspectRatio: 1.4,
};
export const CHART_OPTIONS3 = {
    maintainAspectRatio: false,
    aspectRatio: 1.4

};
export const STACKEDBAR_OPTIONS = {
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true, beginAtZero: true, max: 206
        },
    },
    maintainAspectRatio: false,
    aspectRatio: 1,
    indexAxis: 'y'
};

export const PRODUCT_OPTIONS = [
    { label: 'TODOS', value: '-1' },
    { label: 'Product A', value: '101' },
    { label: 'Product B', value: '102' },
    { label: 'Product C', value: '103' },
];

export const DARKCOLORS = [
    'darkred',
    'darkorchid',
    'darkgoldenrod',
    'darkgreen',
    'darkviolet',
    'darkcyan',
    'rgb(33, 150, 243)',
    'darkturquoise',
    'darkslategrey',
    'black',
    'darkgrey',
]

export const chooseOptions = { icon: 'pi pi-fw pi-plus', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
export const uploadOptions = { icon: 'pi pi-fw pi-save', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
export const cancelOptions = { icon: 'pi pi-fw pi-trash', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };
