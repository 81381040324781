import React, {FC} from 'react'

import { Chart } from 'primereact'

type Props = {
    mainClassName: string
}

const Wallet: FC<Props> = ({ mainClassName }) => {
    const data = {
        labels: [
            'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
            'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
        ],
        datasets: [
            {
                label: 'Cartera Vencida',
                data: [5000, 5500, 6000, 5500, 3500, 5500, 6000, 5500, 5000, 5500, 6000, 5500], // Ajusta los valores según tus necesidades
                fill: true,
                borderColor: 'rgba(255, 0, 0, 0.5)',
                backgroundColor: 'rgba(255, 0, 0, 0.3)',
                tension: 0.1, // Suaviza las líneas
                pointStyle: 'circle',
                pointRadius: 5,
                pointHoverRadius: 20,
            },
            {
                label: 'Cartera Total',
                data: [20000, 21000, 22000, 20000, 16000, 19000, 21000, 21000, 17000, 11000, 16000, 21000], // Ajusta los valores según tus necesidades
                fill: true,
                backgroundColor: 'rgba(0, 100, 0, 0.3)',
                tension: 0.1, // Suaviza las líneas
                pointStyle: 'circle',
                pointRadius: 5,
                pointHoverRadius: 20, borderColor: 'rgba(0, 100, 0, 0.7)',
            },
        ],
    };
    const options = {

        maintainAspectRatio: false,
        aspectRatio: 1.2,
    };
    return (
        <div className={mainClassName}>
            <h6>Cartera</h6>

            <Chart type="line" data={data} options={options} />
        </div>
    )
}

export default Wallet