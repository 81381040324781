export const formatBody = (value: any, prefix: string) => {
     if (prefix === 's') {
         return value;
     } else if (prefix === 'n') {
         return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
     } else if (prefix === 'p') {
         return `${value.toFixed(2)}%`;
     }
     return value;
 };


export const columnStyle = (prefix: string) => {
     return prefix === 'n' || prefix === 'p' ? { textAlign: 'right' } : {};
};