import React, { useEffect } from 'react'
import { Chart, Panel } from 'primereact'
import { CHART_OPTIONS2 } from '../../../../constants/constants.ts';

const noData = () =>
    <h6 style={{ textAlign: 'center' }}>Sin datos para mostrar</h6>

const CardChart = ({ title, data }) => {
    useEffect(() => {
        const pContent = document.querySelectorAll('.p-panel-content')
        if (pContent) {
            pContent.forEach((item) => {
                // item.style.border = 'none'
            })
        }
    }, [data])
    return (
        <Panel header={title} className=''>
            {data ? <Chart type="line" data={data} options={CHART_OPTIONS2} /> : noData()}
        </Panel>
    )
}

export default CardChart