import React, { useEffect, useState } from 'react'
import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton";


type RadioItem = {
     name: string,
     key: number
}
type RadioItemsList = {
     radioOptions: RadioItem[];
    onValueChange: (value: any) => void;
}

const DEFAULTS = { name: 'DEFAULT', key: -1 }
const RadioButtonGroup:React.FC<RadioItemsList> = ({radioOptions, onValueChange}) => {

     const [selectedOption, setSelectedOption] = useState<RadioItem>(DEFAULTS);

     useEffect(()=>{
          if(selectedOption === null){
              onValueChange(selectedOption);
          }
          else {
              onValueChange(selectedOption.key);
          }
      }, [selectedOption])
      
     return (
          <div className="card flex justify-content-center">
               {/* <span className='cardTitleText'>{title}</span> */}
               <div className="flex flex-column gap-3 p-2">
                    {radioOptions.map((option) => {
                         return (
                         <div key={option.key} className="flex align-items-center">
                                   <RadioButton 
                                        inputId={option.key.toString()} 
                                        name="category" 
                                        value={option} 
                                        onChange={(e: RadioButtonChangeEvent) => setSelectedOption(e.value)} checked={selectedOption.key === option.key} />
                                   <label 
                                        htmlFor={option.key.toString()} 
                                        className="ml-1"
                                   >{option.name}</label>
                         </div>
                         );
                    })}
               </div>
        </div>
     )
}

export default RadioButtonGroup