import React, { useState } from 'react'
import { Dialog } from 'primereact'
import DetailContent from './DetailContent';
import { TreePin } from '../../types';

type Props = {
     isVisible: boolean;
     folderId: number;
     clean: () => void;
     pin: TreePin
}

const TreeDetail: React.FC<Props> = ({pin,isVisible,folderId,clean}) => {
     // const [visibleTemporal, setVisibleTemporal] = useState(false)
     return (
          <section>
               <Dialog 
                         visible={isVisible} 
                         onHide={clean} 
                         header={pin.customerConcept}
                         headerStyle={{margin:0,padding:'10px 10px 0 1.5rem'}}
                         position='top'
                         
               >
                    <DetailContent
                         pin={pin}
                         folderId={folderId}
                    />
               </Dialog>
               {/* <button onClick={()=>{setVisibleTemporal(true)}}>abrir modal ejemplo</button> */}
          </section>
     )
}

export default TreeDetail