import axios from "axios"
import { Filters } from "./types"

const MAQUINARY_RESULTS = '/backend/scorecard/gtmqnryrslt'
const POSVENTA_RESULTS = '/backend/scorecard/gtpsvntrslt'
const ACTIVES_WALLET_RESULTS = '/backend/scorecard/gtctvsqltwllt'

export const GetMaquinaryResult = async (filters: Filters) => {
     try{
          const {branch, from, to} = filters
          const response = await axios.get(MAQUINARY_RESULTS,{
               params:{
                    branch,
                    from,
                    to
               }
          })

          if(response.data[0].result){
               return OrderData(JSON.parse(response.data[0].result))
          }
          else{
               return []
          }

     }
     catch(error) {
          console.log(error)
     }
}

export const GetPosventaResult = async (filters: Filters) => {
     try{
          const {branch, from, to} = filters
          const response = await axios.get(POSVENTA_RESULTS,{
               params:{
                    branch,
                    from,
                    to
               }
          })

          if(response.data[0].result){
               return OrderDataPV(JSON.parse(response.data[0].result))
          }
          else{
               return []
          }

     }
     catch(error) {
          console.log(error)
     }
}

export const GetActivesQualityWallet = async (filters: Filters) => {
     try{
          const {branch, department} = filters
          const response = await axios.get(ACTIVES_WALLET_RESULTS,{
               params:{
                    branch,
                    department
               }
          })

          if(response.data[0].result){
               return OrderDataAQW(JSON.parse(response.data[0].result))
          }
          else{
               return []
          }

     }
     catch(error) {
          console.log(error)
     }
}



const OrderData = (data: any) => {
     const order = ["Tractores", "Usados","AG Precisión", "Autoguiado", "Total"];

     const sortedData = data.sort((a:any, b:any) => {
          const indexA = order.indexOf(a.line);
          const indexB = order.indexOf(b.line);
          return indexA - indexB;
     });
     
     return sortedData
}

const OrderDataPV = (data: any) => {
     const order = ["Refacciones", "Agroferretera","Servicio", "Total"];

     const sortedData = data.sort((a:any, b:any) => {
          const indexA = order.indexOf(a.line);
          const indexB = order.indexOf(b.line);
          return indexA - indexB;
     });
     
     return sortedData
}

const OrderDataAQW = (data: any) => {
     const order = ["Total", "Vencida", "Porcentaje"];

     const sortedData = data.sort((a:any, b:any) => {
          const indexA = order.indexOf(a.line);
          const indexB = order.indexOf(b.line);
          return indexA - indexB;
     });
     
     return sortedData
}