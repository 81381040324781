/* eslint-disable react-hooks/exhaustive-deps */
import './SeguimientoClientes.css';
import {
    Panel,
    Chart,
    DataTable,
    Column,
    Dialog,
    InputTextarea,
    Dropdown,
    Button
} from 'primereact';

import { TableHeader } from '../../../general_components/TableHeader';
import { useEffect, useState } from 'react';
import { GetQuotes, GetQuotesPerYear, GetQuotesPerMonth, GetStatues, UpdateQuotes } from './useSeguimientoClientes';
import EditRow from '../../../assets/edit_icon.png';
import { SELLERS_REF, STACKEDBAR_OPTIONS, SUCURSAL_OPTIONS } from '../../../constants/constants.ts';
import { validateFilters } from '../../../helpers';

export default function SeguimientoClientes() {

    const [showEdit, setShowEdit] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [numPartsDetail, setNumPartsDetails] = useState(null);

    const [quotes, setQuotes] = useState();
    const [quotesOriginal, setQuotesOriginal] = useState();

    const [quotesStatues, setQuotesStatues] = useState();
    
    const [quotesPerYear, setQuotesPerYear] = useState();
    const [quotesPerMonth, setQuotesPerMonth] = useState();
    const [quoteEditable, setQuoteEditable] = useState({
        quote: null,
        status: null,
        note: ''
    });

    const [filters, setFilters] = useState({
        branch: null,
        seller: null,
        status: null

    });
    const [onlyNoNotes, setOnlyNoNotes] = useState(false);
    const [globalFilter, setGlobalFilter] = useState('');

    useEffect(() => {
        const obtainBasicData = async () => {
            const statues = await GetStatues();
            setQuotesStatues(statues);
        };

        const obtainAllData = async () => {
            const quotes = await GetQuotes(filters);
            setQuotes(onlyNoNotes ? quotes.filter(quote => !quote.cot_notes) : quotes);
            setQuotesOriginal(quotes);

            const quotesPerYear = await GetQuotesPerYear(filters);
            setQuotesPerYear(quotesPerYear);

            const quotesPerMonth = await GetQuotesPerMonth(filters);
            setQuotesPerMonth(quotesPerMonth);
        }

        obtainBasicData();
        validateFilters(filters, obtainAllData)

    }, [filters]);

    useEffect(() => {
      
    
      console.log('cambiaron los status')
    }, [quotesStatues])
    


    const datita = quotesPerYear

    useEffect(() => {
        if (!quotes) {
            return
        }
        const filteredQuotes = onlyNoNotes ? quotes.filter(quote => !quote.cot_notes) : quotesOriginal
        setQuotes(filteredQuotes);

    }, [onlyNoNotes])
    // #region OLD WAY TO FETCH DATA
    //useEffect(() => {
    //    //GetSellers();
    //    GetquoteStatues();
    //    if (Object.values(filters).every(propValue => propValue !== null)) {
    //        Getquotes(filters);
    //        GetquotesPerYear(filters);
    //        GetquotesPerMonth(filters);
    //    }
    //}, [showEdit, filters]);


    ////const GetSellers = async () => {
    ////    await GetSellersRef();
    ////}
    //const Getquotes = async (filters) => {
    //    try {
    //        const quotes = await GetQuotes(filters);
    //        setQuotes(quotes);
    //    } catch {
    //        throw Error();
    //    }
    //};
    //const GetquotesPerYear = async (filters) => {
    //    try {
    //        const quotes = await GetQuotesPerYear(filters);
    //        setQuotesPerYear(quotes);
    //    } catch(e) {
    //        console.log(e)
    //    }
    //};
    //const GetquotesPerMonth = async () => {
    //    try {
    //        const quotes = await GetQuotesPerMonth();
    //        setQuotesPerMonth(quotes);
    //    } catch (e) {
    //        console.log(e)
    //    }
    //};
    //const GetquoteStatues = async () => {
    //    try {
    //        const quotes = await GetStatues();
    //        setQuotesStatues(quotes);
    //    } catch (e) {
    //        console.log(e)
    //    }
    //};
    // #endregion


    const updateFilters = (value, identifier) => {
        switch (identifier) {
            case 'branch':
                setFilters({
                    ...filters,
                    branch: value
                });
                break;
            case 'seller':
                setFilters({
                    ...filters,
                    seller: value
                });
                break;
            case 'cotStatus':
                setFilters({
                    ...filters,
                    status: value
                });;
                break;
            default:
                break;
        }
    };

    const openModal = () => {
        document.body.classList.add('modal-open');
    };
    const closeModal = () => {
        document.body.classList.remove('modal-open');
    };

    const editRow = (data) => {
        return (
            <div style={{ display: 'grid', placeContent: 'center' }}>
                <img
                    className="editIcon"
                    alt={"edit icon"}
                    src={EditRow}
                    style={{ width: 20 }}
                    onClick={() => {
                        setShowEdit(true);
                        openModal();
                        setQuoteEditable({
                            quote: data.id_cot,
                            status: data.id_cot_status,
                            note: data.cot_notes
                        })


                    }}
                />
            </div>
        );
    };

    const footerQuotesEdit = (data) => {
        return (
            <Button
                severity="success"
                className="p-text-center w-full"
                style={{ display: 'grid', placeContent: 'center' }}
                onClick={() => {
                    UpdateQuotes(quoteEditable);
                    setShowEdit(false);
                    closeModal();

                }}
            >
                Guardar
            </Button>
        );
    };
    const footerDetailsParts = (data) => {
        return (
            <Button
                severity="danger"
                className="p-text-center w-full"
                style={{ display: 'grid', placeContent: 'center', marginTop: '1rem' }}
                onClick={() => { setShowDetails(false); closeModal() }}
            >
                Regresar
            </Button>
        );
    };

    const headerDetailsParts = () => {

        const totalCantidad = numPartsDetail.reduce((sum, item) => sum + item.nCantidad, 0);
        const totalDinero = numPartsDetail.reduce((sum, item) => sum + (item.nCantidad * item.mPrecioUnitario), 0);
        return (
            <div>
                <h3>Numeros de Parte</h3>
                <span style={{ color: "rgba(0,0,0,0.5)" }}>
                    {numPartsDetail.length} {numPartsDetail.length === 1 ? 'numero de parte' : 'numeros de parte'} {' - '}
                    {totalCantidad} {totalCantidad === 1 ? 'pieza' : 'piezas'} -
                    total: {totalDinero.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                </span>

            </div>
            //<Button
            //    severity="danger"
            //    className="p-text-center w-full"
            //    style={{ display: 'grid', placeContent: 'center', marginTop: '1rem' }}
            //    onClick={() => { setShowDetails(false); closeModal() }}
            //>
            //    Regresar
            //</Button>
        );
    };
    const showNumParts = (data) => {

        if (data.details === '') {
            return 'Sin numeros de parte'
        }
        const numPartsComplete = JSON.parse(data.details);
        const numParts = numPartsComplete.map(item => item.sNumeroParte);
        //const numPartsConc = numParts.join(',');
        //const numPartsToDisplay =
        //    numParts.length > 1 ? numPartsConc.slice(1, 20).concat('...') : numPartsConc.slice(1, 20);


        return (
            <div style={{ display: 'grid', placeContent: 'center' }}>
                <Button
                    severity="secundary"
                    text
                    className=" w-full"
                    style={{
                        display: 'grid',
                        placeContent: 'center',
                        textDecoration: 'underline'
                    }}
                    onClick={() => {
                        openModal(); setNumPartsDetails(numPartsComplete); setShowDetails(true)
                    }
                    }
                >
                    Ver numeros de parte ({numParts.length})
                </Button>
            </div>
        );
    };
    const estatusTemplate = (data) => {
        return (
            <div style={{ backgroundColor: data.cot_status_color, display: 'grid', placeContent: 'center', borderRadius: 4, color: 'white', fontSize: '90%', margin: '.7em' }}>
                {data.cot_status}
            </div>

        )
    }

    const dateFormated = (e) => {
        const fecha = new Date(e.date);
        const dia = fecha.getDate();
        const mes = fecha.getMonth() + 1;
        const anio = fecha.getFullYear();
        const fechaFormateada = `${dia}/${mes}/${anio}`;

        return <span>{fechaFormateada}</span>
    }
    const bodyNotes = (e) => {
        return (
            <div style={{ backgroundColor: e.cot_notes === '' ? 'transparent' : 'rgba(11,127,200,0.07)', padding: '1rem', borderRadius: '1rem' }}>
                <span style={{ fontWeight: '400', fontStyle: 'italic' }}>{e.cot_notes.toUpperCase()}</span>
            </div>
        )
    }
    const renderTotal = (e) => {
        return <span>{e.total.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
    }


    return (
        <div className="content">
            {/*<Panel header="Indicadores Financieros" className="panel">*/}
            {/*    <section className='cardSection'>*/}
            {/*        {IndicatorCard("Operaciones", 120, "green", "")}*/}
            {/*        {IndicatorCard("Facturas", 100, "blue", "")}*/}
            {/*        {IndicatorCard("Cancelaciones", 20, "red", "")}*/}
            {/*        {IndicatorCard("Utilidad Generada", 40, "lightblue", "$")}*/}
            {/*        {IndicatorCard("Margen de Utilidad", 35, "darkblue", "%")}*/}
            {/*    </section>*/}
            {/*</Panel>*/}


            <section style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: '1rem' }}>

                <Panel header='Opciones de Filtrado' className='panel'>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem', height: '17.75rem', marginTop: '1em' }}>
                        <span className="p-float-label mt-10">
                            <Dropdown
                                inputId="dd-sucursal"
                                value={filters.branch}
                                options={SUCURSAL_OPTIONS}
                                onChange={(e) => updateFilters(e.value, 'branch')}
                                placeholder="Sucursal"
                                className="w-full md:w-14rem"

                            />
                            <label htmlFor="dd-sucursal">Sucursal</label>
                        </span>
                        <span className="p-float-label mt-10">
                            <Dropdown
                                inputId="dd-sucursal"
                                value={filters.seller}
                                options={SELLERS_REF}
                                onChange={(e) => updateFilters(e.value, 'seller')}
                                placeholder="Asesor"
                                className="w-full md:w-14rem"

                            />
                            <label htmlFor="dd-sucursal">Asesor</label>
                        </span>

                        <span className="p-float-label mt-10">
                            <Dropdown
                                inputId="dd-sucursal"
                                value={filters.status}
                                options={quotesStatues ? [{ value: -1, label: 'TODOS' }, ...quotesStatues] : quotesStatues}
                                onChange={(e) => updateFilters(e.value, 'cotStatus')}
                                placeholder="Estatus"
                                className="w-full md:w-14rem"

                            />
                            <label htmlFor="dd-sucursal">Estatus</label>
                        </span>
                    </div>
                </Panel>
                <Panel header="Nivel de Eficiencia" style={{ flex: 1 }} className="panel">
                    <Chart type="bar" data={(!showEdit && !showDetails && quotesPerMonth) && quotesPerMonth} options={options} style={{ width: '100%' }} />
                </Panel>
                <Panel header="Cotizaciones Concretadas" className="panel" style={{ flex: 0.3 }}>
                    <Chart type="pie" data={(!showEdit && !showDetails) && quotesPerYear} options={options2} />
                </Panel>
            </section>

            <Chart
                type="bar"
                data={
                    {
                        labels: [''],
                        datasets: quotesStatues && quotesStatues.map(status => ({
                            label: status.label,
                            data: [quotes && quotes.filter(item => (item.cot_status === status.label)).length],
                            backgroundColor: status.color,
                        }))
                    }
                }
                options={STACKEDBAR_OPTIONS}
                style={{ height: '6rem' }}
            />

            <DataTable
                size="small"
                header={TableHeader("Detalle", quotes, setGlobalFilter, onlyNoNotes, setOnlyNoNotes)}
                paginator
                stripedRows
                showGridlines
                rows={quotes ? 20 : 20}
                scrollable scrollHeight="600px"
                selectionMode="single"
                rowsPerPageOptions={[5, 10, 25, 50, 100, quotes ? quotes.length : 20]}
                tableStyle={{ minWidth: '50rem' }}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords}"
                value={quotes}
                className="datatableQuotes panel"
                globalFilter={globalFilter}
            >
                <Column header="#" headerStyle={{ width: '3rem' }} body={(data, options) => options.rowIndex + 1}></Column>
                <Column field="folio_cot" header="Folio" sortable bodyClassName="p-text-center" style={{ width: "3rem" }} />
                <Column field="name_suc" header="Sucursal" sortable bodyClassName="p-text-center" style={{ width: "3rem" }} />
                <Column field="name_seller" header="Vendedor" sortable bodyClassName="p-text-center" style={{ width: "12rem" }} />
                <Column field="date" header="Fecha" sortable bodyClassName="p-text-center" style={{ width: "5rem" }} body={dateFormated} />

                {/*<Column field="cot_month_name" header="Mes" sortable bodyClassName="p-text-center" style={{ width: "5rem" }} />*/}
                {/*<Column field="cot_year" header="Periodo" sortable bodyClassName="p-text-center" style={{ width: "5rem" }} />*/}
                {/*<Column field="cot_status" header="Estatus" sortable bodyClassName="p-text-center" style={{ width: "10rem" }} />*/}
                <Column field="cot_status" header="Estatus" sortable body={estatusTemplate} style={{ width: "8rem" }} />
                <Column header="Numeros de parte" sortable headerClassName="p-text-center" bodyClassName="p-text-center" style={{ width: '15rem' }} body={showNumParts} />
                <Column field="total" header="Total" sortable bodyClassName="p-text-center" body={renderTotal} style={{ width: "3rem" }} />
                <Column field="customer" header="Cliente" sortable bodyClassName="p-text-center" style={{ width: "12rem" }} />
                <Column field="cot_notes" header="Notas" sortable bodyClassName="p-text-center" body={bodyNotes} style={{ width: "25rem" }} />
                <Column header="" bodyClassName="p-text-center" style={{ width: '3rem' }} body={editRow} />
            </DataTable>

            <Dialog visible={showEdit} style={{ width: '20rem' }} header="Actualizar" modal onHide={() => { setShowEdit(false); closeModal() }} footer={footerQuotesEdit} >
                <section style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <>Estatus</>
                    <Dropdown
                        value={quoteEditable.status}
                        onChange={(e) => { setQuoteEditable({ ...quoteEditable, status: e.value, }); }}
                        options={quotesStatues}
                        placeholder="Seleccionar estado"
                        className="w-full mb-4 mt-2"
                        autoFocus
                    />
                    <>Notas</>
                    <InputTextarea
                        id="username"
                        className="p-inputtext-sm w-full mt-1"
                        value={quoteEditable.note}
                        onChange={(e) => { setQuoteEditable({ ...quoteEditable, note: e.target.value }); }}
                        rows={4}
                    />
                </section>
            </Dialog>

            <Dialog visible={showDetails} style={{ width: 'auto', padding: 0 }} header={headerDetailsParts} modal onHide={() => {
                setShowDetails(false); closeModal();
            }} footer={footerDetailsParts} >

                {numPartsDetail && (
                    <>
                        <section style={{ width: 'auto', padding: 0 }} >
                            {numPartsDetail.map((item, index) =>
                                <div key={index} className='flex m-2 gap-2 item-numPart'>
                                    <img src="https://placehold.co/100x100/png" alt={item.sNumeroParte} style={{ height: 100, width: 100 }} />
                                    <section className="flex row">
                                        <span className="font-bold">{item.sNumeroParte} ({item.sLinea}) </span>
                                        <span>{(item.mPrecioUnitario).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} c/u</span>

                                        <span>{item.nCantidad} {item.nCantidad > 1 ? 'Unidades' : 'Unidad'}</span>
                                        <span>Total: {(item.nCantidad * item.mPrecioUnitario).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>

                                    </section>
                                </div>
                            )}
                        </section>
                    </>

                )}
            </Dialog>

        </div>
    );
}


const options = {
    maintainAspectRatio: false,
    aspectRatio: 1,
    scales: {
        x: {
            stacked: true,
            beginAtZero: true,
        },
        y: {
            stacked: true,
            beginAtZero: true,
        },
    },
};
const options2 = {
    maintainAspectRatio: false,
    aspectRatio: 1,
    plugins: {
        legend: {
            display: true,
            position: 'bottom',
        },
    },
};