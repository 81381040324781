import React from 'react'

const InventoryUnits = () => {
     return (
          <section className="grid-item">
               <span id='tableResultTitle'>Unidades en Inventario</span>   
               <div id="unitsInInventory">
                    <section>
                         <header>
                              <div></div>
                              <div>Nuevos</div>
                              <div></div>
                         </header>
                         <div>
                              <div>
                                   <span>
                                        Tractores
                                   </span>
                                   
                                   <span>
                                        10
                                   </span>
                              </div>
                              <div>
                                   <span>
                                        Combinadas
                                   </span>
                                   
                                   <span>
                                        0
                                   </span>
                              </div>
                              <div>
                                   <span>
                                        Implementos
                                   </span>
                                   
                                   <span>
                                        22
                                   </span>
                              </div>
                         </div>
                    </section>
                    <section>
                         <header>
                              <div></div>
                              <div>Usados</div>
                              <div></div>
                         </header>
                         <div>
                              <div>
                                   <span>
                                        Tractores
                                   </span>
                                   <span>
                                        10
                                   </span>
                              </div>
                              <div>
                                   <span>
                                        Combinadas
                                   </span>
                                   
                                   <span>
                                        0
                                   </span>
                              </div>
                              <div>
                                   <span>
                                        Implementos
                                   </span>
                                   
                                   <span>
                                        22
                                   </span>
                              </div>
                         </div>
                    </section>
               </div>
          </section>
     )
}

export default InventoryUnits