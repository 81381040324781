import React, { useEffect, FC, useState } from 'react';
import { Chart } from 'primereact';
import { GetMarketShareWithoutType } from '../request';
import { Filters } from '../../../types';
import { validateFilters } from '../../../../../../helpers';
import { MARKET_SHARE_CHART_OPTIONS } from '../../../constants';

type MarketData = {
    brand: string;
    amount: number;
    color: string;
};

type Props = {
    mainClassName: string;
    filters: Filters;
};

const MarketShare: FC<Props> = ({ mainClassName, filters }) => {
    
    const [marketData, setMarketData] = useState<MarketData[]>([]);

    useEffect(() => {
        const initialize = async () => {
            const response = await GetMarketShareWithoutType(filters);

            if (!response) {
                setMarketData([]);
                return;
            }

            const data = response.map((item: any) => ({
                brand: item.sNombre,
                amount: item.nCantidad,
                color: item.sColor,
            }));

            setMarketData(data);
        };

        if(!validateFilters(filters, initialize)){
            setDataEmpty();
        }

    }, [filters]);

    useEffect(()=>{
        if(!validateFilters(filters, setDataEmpty)){
            setMarketData([]);
        }
    },[filters.employees])

    const setDataEmpty = () => {
        setMarketData([]);
    }

    const totalAmount = marketData.reduce((total, item) => total + item.amount, 0);

    const centerTextPlugin = {
        id: 'centerTextPlugin',
        afterDraw: (chart: any) => {
            const { ctx, chartArea: { width, height } } = chart;
    
            ctx.save();

            const fontSize = (height / 150).toFixed(2);
            ctx.font = `${fontSize}em "Helvetica Neue", Helvetica, Arial, sans-serif`;
            ctx.textBaseline = "middle";
            ctx.textAlign = "center";
            ctx.fillStyle = "rgba(0, 0, 0, 0.6)"; 
    
            const text = `${totalAmount}`;
            const textX = width / 2;
            const textY = height / 2 - 10; 
    
            ctx.fillText(text, textX, textY);
    
            const labelFontSize = (height / 200).toFixed(2); 
            ctx.font = `${labelFontSize}em "Helvetica Neue", Helvetica, Arial, sans-serif`;
            ctx.fillStyle = "rgba(0, 0, 0, 0.5)"; 
    
            const label = "Tractores";
            const labelX = width / 2;
            const labelY = textY + parseFloat(fontSize) * 16 - 2;
            
            ctx.fillText(label, labelX, labelY);
    
            ctx.restore();
        }
    };
    

    const chartOptions = {
        ...MARKET_SHARE_CHART_OPTIONS,
        plugins: {
            ...MARKET_SHARE_CHART_OPTIONS.plugins,
            centerTextPlugin,
        },
    };

    return (
        <div className={mainClassName}>
            <h6>Market Share por Tractores</h6>
            {marketData.length !== 0 && (
                <Chart
                    type="doughnut"
                    // type="pie"
                    data={{
                        labels: marketData.map(item => item.brand),
                        datasets: [
                            {
                                data: marketData.map(item => item.amount),
                                backgroundColor: marketData.map(item => item.color),
                            },
                        ],
                    }}
                    options={chartOptions}
                    style={{ paddingRight: '4rem' }}
                    plugins={[centerTextPlugin]}
                />
            )}
        </div>
    );
};

export default MarketShare;
