
import React, { useEffect, useState } from 'react';
import { Dropdown, Panel, Checkbox, MultiSelect } from 'primereact'

// import { MONTH_OPTIONS, SUCURSAL_OPTIONS } from '../../../../constants/constants.ts';
import { SUCURSAL_OPTIONS } from '../../../../constants/constants';
import DropdownBranch from '../../../../general_components/DropdownBranch';
import DatePickerRange from '../../../../general_components/DatePickerRange';
import Card from '../../../../general_components/Card';
import { FiltersT, TreeModuleFilters } from '../types';
import DropdownEmployee from '../../../../general_components/DropdownEmployee';
import DropdownEmployeeSingle from '../../../../general_components/DropdownEmployeeSingle';
import DropdownDepartmentSingle from '../../../../general_components/DropdownDepartmentSingle';
import RadioButtonGroup from '../../../../general_components/RadioButtonGroup';
import Count from './Count';


type Props = {
    filters: TreeModuleFilters,
    setFilters: React.Dispatch<React.SetStateAction<TreeModuleFilters>>
}

const Filters: React.FC<Props> = ({ filters, setFilters }) => {


    return (
        <Card title='Opciones de filtrado' height='' width='18rem'>
            <div className='filtersContent'>
                    <DropdownBranch
                        onValueChange={(e:any) => { setFilters({ 
                            ...filters, 
                            branch: e 
                        })}}
                    />
                    <DropdownDepartmentSingle
                        withAll
                        onValueChange={(value)=>setFilters({
                            ...filters,
                            department: value
                        })}
                    />
                    <DropdownEmployeeSingle
                        withAll
                        branch={filters.branch}
                        departments={filters.department}
                        onValueChange={(value)=>setFilters({
                            ...filters,
                            employee: value
                        })}
                    />
                    <DatePickerRange
                        onRangeChange={(e:any) => { setFilters({ 
                            ...filters, 
                            from: e.from, 
                            to: e.to 
                        })}}
                    />
                    <RadioButtonGroup
                        radioOptions={[
                            {name:'Todas las Plantaciones', key: -1},
                            {name:'Solo adicionales',key:1},
                            {name:'Solo de Venta de Tractores',key:2}
                        ]}
                        onValueChange={(value)=>setFilters({
                            ...filters,
                            onlyAditionals: value
                        })}
                    />
                    {/* <Count filters={filters}/> */}
            </div>
        </Card>
    )
}
export default Filters