import React from 'react'
import './styles.css'
import EvidencesGalleria from './EvidencesGalleria'
import { TreePin } from '../../types'

type Props = {
     pin: TreePin,
     folderId: number
}

const DetailContent: React.FC<Props> = ({pin, folderId}) => {

     const variusTrees = pin.amountPerLocation > 1
     const isAdditional = pin.recordId !== 0 

     return (
          <div id='dvTreeDetailContent'>

               <span>
                    {pin.amountPerLocation} 
                    {variusTrees ? 
                         ' Árboles en esta ubicación.' : 
                         ' Árbol en esta ubicación.'
                    } 
               </span>

               <EvidencesGalleria 
                    folderId={folderId} 
                    isAdditional={isAdditional} 
                    pin={pin}
               />
          </div>
     )
}

export default DetailContent