export const MARKET_SHARE_CHART_OPTIONS = {
     maintainAspectRatio: false,
     aspectRatio: 1.2,
     plugins: {
         legend: {
             display: true,
             position: 'right',
             labels: {
                 color: 'black',
             },
         },

     },
 };

export const PROSPECTING_BY_STATUS_CHART_OPTIONS = {
    maintainAspectRatio: false,
    aspectRatio: 2.1,
    plugins: {
        legend: {
            display: true,
            position: 'right',
        },
    },
};

 export const RED_ARROW_DOWN = "https://img.icons8.com/?size=100&id=7800&format=png&color=FA5252"
 export const GREEN_ARROW_UP = "https://img.icons8.com/?size=100&id=7801&format=png&color=40C057"
 export const GRAY_MINUS = "https://img.icons8.com/?size=100&id=79029&format=png&color=737373"