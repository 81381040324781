import React, { FC, useEffect, useState } from 'react'
import '../../../styles/SalesLeaders.css'
import { GetEmployeePic } from '../helpers'
import { GREEN_ARROW_UP, RED_ARROW_DOWN } from '../../../constants'
import { Filters } from '../../../types'
import { validateFilters } from '../../../../../../helpers'
import { GetSalesLeaders } from '../request'
import { Tooltip } from 'primereact/tooltip';

type Props = {
    mainClassName: string,
    filters: Filters
}

type LeaderProps = {
    name: string;
    shortName: string;
    employeeId: number;
    invoicing: string;
    sales: string;
    parts: string;
    agro: string;
    service: string;
    agp: string;
    tendency: number;
    index: number;
}

type TooltipProps = {
    leaderData: LeaderProps;
}

const TooltipDetail: React.FC<TooltipProps> = (Props) => {
    const { leaderData } = Props;
    const { index, name, sales, parts, agro, service, agp } = leaderData;

    return (
        <Tooltip target={`#labelInvoicing-${index}`} position='bottom' mouseTrack mouseTrackTop={40}>
            <div>
                <table style={{ 
                    borderCollapse: 'collapse', 
                    width: '100%', 
                    textAlign: 'center', 
                    fontSize: '80%',  
                    color: 'white' 
                }}>
                    <thead>
                        <tr style={{ fontWeight: 'bold' }}>
                            <td style={{ padding: '0 15px' }}>Ventas</td>
                            <td style={{ padding: '0 15px' }}>Refacciones</td>
                            <td style={{ padding: '0 15px' }}>Agroferretera</td>
                            <td style={{ padding: '0 15px' }}>Servicio</td>
                            <td style={{ padding: '0 15px' }}>Agricultura de Precisión</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{ borderBottom: '1px solid gray' }}>
                            <td style={{ padding: '0 15px' }}>{sales}</td>
                            <td style={{ padding: '0 15px' }}>{parts}</td>
                            <td style={{ padding: '0 15px' }}>{agro}</td>
                            <td style={{ padding: '0 15px' }}>{service}</td>
                            <td style={{ padding: '0 15px' }}>{agp}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Tooltip>
    );
};



const LeaderItem: React.FC<LeaderProps> = (Props) => {

    const { name, employeeId, invoicing, tendency, index } = Props;
    const employeePic = GetEmployeePic(employeeId)

    const GetTendencyIndicator = () => {
        if(tendency === 0) return;
        return tendency == 1 ? GREEN_ARROW_UP : RED_ARROW_DOWN;
    }

    return(
        <div id={`leaderItem-${index}`} className={'leaderItem'} >
            
            <label id="leaderIndex">{index}</label>
            <img 
                id='leaderImg'
                src={employeePic}
            />
            <div id='leaderData'>
                <label> {name} </label>
                <br/>
                <label id={`labelInvoicing-${index}`} className='labelInvoicing'> {invoicing} </label>
            </div>
            {tendency != 0 && (
                <img 
                    id='leaderTendency'
                    src={GetTendencyIndicator()}
                />
            )}
            <TooltipDetail leaderData={Props}/>
        </div>
    )
}


const SalesLeaders: FC<Props> = ({ mainClassName, filters }) => {

    const [leaders, setLeaders] = useState<LeaderProps[]>()

    useEffect(() => {
        const initialize = async () => {
            const response = await GetSalesLeaders(filters);

            if (!response) {
                setLeaders([]);
                return;
            }
            console.log('Desde el cliente; ',response)
            setLeaders(response);
        };

        if(!validateFilters(filters, initialize)){
            setLeaders([])
        }
    }, [filters]);

    return (
        <div className={mainClassName}>
            <h6>Lideres en Ventas</h6>
            <div id='subContainer'>
                <section>
                    {leaders && (
                        leaders.map((leader, index) => (
                            <LeaderItem 
                                key={index}
                                index={index + 1}
                                name={leader.name}
                                shortName='EJEMPLO'
                                employeeId={leader.employeeId}
                                invoicing={leader.invoicing}
                                sales={leader.sales}
                                parts={leader.parts}
                                service={leader.service}
                                agro={leader.agro}
                                agp={leader.agp}
                                tendency={leader.tendency}
                            />
                        ))
                    )}
                </section>
            </div>
        </div >
    )
}

export default SalesLeaders