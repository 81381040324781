import React, { useEffect } from 'react'
// import MapExample from './Components/ExampleMap.tsx';
// import Filters from './Components/Filters'
// import { GetDeps } from '../../../request'

const Pops = () => {
     useEffect(()=> {
          const init = async () => {
               try {
                    // const result = await GetDeps();
                    // console.log(result)
               } catch (error) {
                    
               }
          }
          init();
     },[])
     return (
          <div>
               <label>hola</label>
               {/* <Filters/> */}
               {/* <MapExample/> */}
          </div>
     )
}

export default Pops