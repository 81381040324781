import PinTree from './pinTree.png'
import Undo from './undo.png'
import ClearPin from './clearPin.png'
import Foto1 from './foto1.jpeg'
import Foto2 from './foto2.jpeg'
import Foto3 from './foto3.jpeg'
import Arbol  from  './arbol2.png'
import Direction from './direction.png'
import Image  from './image.png'
import LogoAG from './LogoAG.png'
import icon1 from './weekyReport/1.png'
import icon2 from './weekyReport/2.png'
import icon3 from './weekyReport/3.png'
import icon4 from './weekyReport/4.png'
import icon5 from './weekyReport/5.png'


export const PIN_TREE = PinTree
export const UNDO = Undo;
export const CLEAR_PIN = ClearPin
export const FOTO = Foto1
export const FOTO2 = Foto2
export const FOTO3 = Foto3
export const ARBOL = Arbol
export const DIRECTION = Direction
export const IMAGE = Image
export const LOGO_AG = LogoAG
export const ICON_1 = icon1
export const ICON_2 = icon2
export const ICON_3 = icon3
export const ICON_4 = icon4
export const ICON_5 = icon5

