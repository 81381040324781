const useConnection = () => {
    const production = 'https://siia.agroequipos.mx/siia';
    const development = 'http://localhost:44477/scrnrprtdrctn';
    const backend = '/backend/seguimientosurtido/';
    return {
        production,
        development,
        backend
    }
}
export default useConnection;