import axios from "axios";
import { GET_ALL_EMPLOYEES } from "../constants/routes.ts";
import { FETCH_ALL_EMPLOYEES } from "../constants/errors.ts";

const SOURCE = '/backend/budgetadvancement';
const DEPARTMENTS_SELLERS = '/rqstDS';

type EmployeeDetail = {
    branch: number;
    employeeId: number;
    personId: number;
    sellerId: number;
    departmentId: number;
    departmentName: string;
    name: string;
};
  
export const GetEmployees = async (branch: number | null, deps: number[], onlySeellers: boolean) => {
    try {
        const response = await axios.get(GET_ALL_EMPLOYEES);

        if (!response) return null

        let data = response.data as EmployeeDetail[]

        if(branch!=-1){
            data = data.filter(item => item.branch == branch)
        }

        if(deps != null){
            data = data.filter(item => deps.includes(item.departmentId))
        }

        if(onlySeellers){
            data = data.filter(item => item.sellerId != 0)
        }


        const dataHandled = handleDeps(data)

        return dataHandled;

    } catch (error) {
        throw new Error(FETCH_ALL_EMPLOYEES);
    }
}

export const GetEmployeesSingle = async (branch: number, deps: number[] | null) => {
    try {
        const response = await axios.get(GET_ALL_EMPLOYEES);
        if (!response) return null

        let data = response.data as EmployeeDetail[]
        
        if(branch!=-1){
            data = data.filter(item => item.branch == branch)
        }

        if(deps !== null){
            // console.log('Departamento primer',deps)

            // console.log('Departamento primer',[3])
            // console.log("hay diferencia?", deps[0] != -1)
            if(deps[0] != -1){
                data = data.filter((item) => deps.includes(item.departmentId))
            }
        }

        const dataHandled = data
        return dataHandled;

    } catch (error) {
        throw new Error(FETCH_ALL_EMPLOYEES);
    }
}


// export const GetDeps = async () => {
//     try {

//         let parsedData = {};
//         const response = await axios.get(http://localhost:44477/backend/global/rqstllmplys);

//         if (!response) return null
//         return parsedData;

//     } catch (error) {
//         console.error('Error fetching data:', error);
//     }
// }

const handleDeps = (das:any) => {
    
    if (!das) {
        return null;
    }

    const labels = [...new Set(das.map((element:any) => element.departmentName))];

    const data = labels.map(label => {
        const items: any= [];
        das.forEach((element: any) => {
            if (element.departmentName === label) {
                items.push(element)
            }
        });
        return {
            label,
            code: label,
            items
        }

    })

    return data;
    // return null
};


// export const DepartmentsAndSellers = async (branch) => {
//     try {

//         let parsedData = {};
//         const response = await axios.get(${SOURCE}${DEPARTMENTS_SELLERS}, {
//             params: {
//                 branch
//             }
//         });
//         if (!response) return {}

//         for (const key in response.data) {
//             parsedData[key] = JSON.parse(response.data[key]);
//         }
//         parsedData = {
//             ...parsedData,
//             SELLERS: handleDeps(parsedData)
//         }

//         return parsedData;
//     } catch (error) {
//         console.error('Error fetching data:', error);
//     }
// }