import { calculatePercentage } from '../../../../helpers';
import useProcessedData from '../hooks';
import '../styles.css'
import CardData from './CardData';
import CardDataRI from './CardDataRI';
import Filters from './Filters';
import SellerPicture from './SellerPicture';

const FirstLevel = ({ filters, setFilters, dataFetched }) => {

    const { budgetAvancement, grossProfitAndMargin, remainInvoicing } = useProcessedData(dataFetched);

    return (
        <section className='filters-and-resume'>
            <Filters
                filters={filters}
                setFilters={setFilters}
            />
            <CardDataRI
                percentIndicator={'Porcentaje de avance'}
                endPercent={budgetAvancement.percent}
                endAmount={budgetAvancement.amount}
                endRemaning={budgetAvancement.amount - remainInvoicing.amount}
                filters={filters}
            />
            <CardData
                percentIndicator={'Margen'}
                amountIndicator={'Utilidad Bruta'}
                endPercent={grossProfitAndMargin.percent}
                endAmount={grossProfitAndMargin.amount}
                filters={filters}
                invertColors={true}
            />

            <CardData
                percentIndicator={'Porcentaje de facturacion pendiente'}
                amountIndicator={'  Facturación Pendiente'}
                endPercent={calculatePercentage(remainInvoicing.amount, budgetAvancement.amount) / 100}
                endAmount={remainInvoicing.amount}

                invertColors={false}
                filters={filters}
            />
            {/* <CardData
                percentIndicator={'Porcentaje de avance'}
                amountIndicator={'Avance de Facturación'}
                endPercent={budgetAvancement.percent}
                endAmount={budgetAvancement.amount - remainInvoicing.amount}
                filters={filters}
            /> */}
            {
                (dataFetched !== null && Array.isArray(filters.sellers)) &&
                filters.sellers.length === 1 && (
                    <SellerPicture
                        sellerId={dataFetched[0].EMPLOYEE_ID}
                    />
                )
                
            }
        </section>
    )
}

export default FirstLevel;