import React, {FC} from 'react'

type Props = {
     data: any
}


const MesualResultsStatues:FC<Props> = ({data}) => {
     return (
          <section className="grid-item">
               <span id='tableResultTitle'>Estado Mensual</span>
               <table id='tableResultGCon' style={{width: '7cm'}}>
                    <thead>
                         <tr>
                         <th>Concepto</th>
                         <th>Presupuesto</th>
                         <th>Avance</th>
                         <th>%</th>
                         </tr>
                    </thead>
                    <tbody>
                         {data.map((row: any, index: any) => (
                         <tr key={index} className='font-bold'>
                              <td className='text-left'>{row.Concepto}</td>
                              <td>{row.Presupuesto.toLocaleString()}</td>
                              <td>{row.Avance.toLocaleString()}</td>
                              <td>{row.Porcentaje}</td>
                         </tr>
                         ))}
                    </tbody>
               </table>
          </section>
     )
}

export default MesualResultsStatues