import React from 'react';
import '../styles/GenericTableResult.css';

type Props = {
    title: string;
    data: any;
}

const ActivesQualityInventory: React.FC<Props> = ({ title, data }) => {
    return (
        <section className="grid-item">
            <span id='tableResultTitle'>{title}</span>
            <table id='tableResultGCon'>
                <thead>
                    <tr>
                        <th rowSpan={2}>Inventario</th>
                        <th colSpan={4}>Objetivos 2024</th>
                        <th colSpan={2}>Cierre</th>
                    </tr>
                    <tr>
                        <th>Marzo</th>
                        <th>Abril</th>
                        <th>Hoy</th>
                        <th>Mayo</th>
                        <th>2023</th>
                        <th>2022</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row: any, index: any) => (
                        <tr key={index} style={{ padding: 0, height: 2 }}>
                            <th>{row.linea}</th>
                            <td>{row.mensual.marzo}</td>
                            <td>{row.mensual.abril}</td>
                            <td>{row.mensual.hoy}</td>
                            <td>{row.mensual.mayo}</td>
                            <td>{row.anual["2023"]}</td>
                            <td>{row.anual["2022"]}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </section>
    );
}

export default ActivesQualityInventory;
