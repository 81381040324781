import { Chart, Panel } from 'primereact'
import { CHART_OPTIONS3, DEPARTMENTS } from "../../../../constants/constants.ts";

const BudgetAdvancement = ({ filters, data }) => {

    function getDepartmentNamesString() {
        const departmentNames = filters.departments.map(value => {
            const department = DEPARTMENTS.find(dep => dep.value === value.toString());
            return department ? department.label : null;
        });

        const filteredDepartmentNames = departmentNames.filter(name => name !== null);

        return filteredDepartmentNames.length > 0 ? ` ( ${filteredDepartmentNames.join(',  ')} )` : '';
    }
    const noData = () =>
        <h6 style={{ textAlign: 'center' }}>Sin datos para mostrar</h6>

    return (
        <section className='budget-advancement' style={{marginBottom: '1rem'}}>
            <Panel header={"Presupuesto y avance del año en curso " + getDepartmentNamesString() + ''} className='panel'>
                {data ? <Chart type="bar" data={data} options={CHART_OPTIONS3} /> : noData()}
            </Panel>
        </section>
    )
}

export default BudgetAdvancement;