
import axios from 'axios';
import { NewTreeRecord, NewTreeRecordDetail, TreeModuleFilters } from '../types';

const SOURCE = '/trees/plantedtrees';
const SAVE_EVIDENCES = '/svplntdtrs/';

const SAVE_RECORD_DATA ='/nsrtplntcnrbl'
const SAVE_RECORD_DATA_DETAIL ='/nsrtplntnrbdt'

const REQUEST_TREES = '/gtpntrsdt'
const REQUEST_TREE_TO_PLANT = '/gttrstplnt'


export const RequestTreesPinData = async (filters: TreeModuleFilters) => {
    // console.log('empleado para el filtro: ',filters.employee)
    const URL = `${SOURCE}${REQUEST_TREES}`

    const cleanFilters = {
        ...filters,
        department: filters.department ? filters.department[0] : 0
    }
    try {
        const response = await axios.get(URL, {
            params: cleanFilters
        });
        // console.log('response . data',response.data)
        if(response.data[0].result){
            return JSON.parse(response.data[0].result)
        }
        else{
            return []
        }
    } catch (error) {
        console.error(error)
    }
}






export const RequestTreesToPlant = async (filters: TreeModuleFilters) => {
    
    const URL = `${SOURCE}${REQUEST_TREE_TO_PLANT}`;
   
    const { branch, from, to } = filters;

    try {
        const response = await axios.get(URL, {
            params: {
                branch: Number(branch),
                from,
                to
            }
        });
        if(response.data[0].result){
            return JSON.parse(response.data[0].result)
        }
        else{
            return []
        }
    } catch (error) {
        console.error(error)
    }
}






export const saveTreePhotos = async (files: any, id: number, isAdditional: boolean) => {
    // console.log('Fotos: ', files)
    const URL = `${SOURCE}${SAVE_EVIDENCES}${id}/${isAdditional}`
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
        formData.append('images', files[i]);
    }

    const response = await fetch(URL, { // Modificar la URL
        method: 'POST',
        body: formData
    });

    const data = await response.json();

    // console.log(data)
    if (!response.ok) {
        throw new Error(data.message || 'Error uploading images');
    }

    return data;
};


export const saveData = async (data: NewTreeRecord): Promise<number> => {
    try {
        const dataHandled =  {
            ...data,
            //@ts-ignore
            department: data.department[0]
        }
        const response = await axios.post<number>(`${SOURCE}${SAVE_RECORD_DATA}`, dataHandled);
        return response.data;
    } catch (error) {
        throw new Error('Error al guardar el registro de plantación de árbol.');
    }
};

export const saveDataDetail = async (detalles: NewTreeRecordDetail[]): Promise<void> => {
    try {
        const response = await axios.post(`${SOURCE}${SAVE_RECORD_DATA_DETAIL}`, detalles);
        return response.data;
    } catch (error) {
        throw new Error('Error al guardar los detalles de plantación de árbol.');
    }
};

 