
import React, { useEffect, useState } from "react";
import { InputTextarea } from "primereact/inputtextarea";

type Props = {
     onValueChange: (param: string)=>void
 }
 
const TextArea: React.FC<Props> = ({onValueChange}) => {
     const [value, setValue] = useState<string>('');

     useEffect(() => {
          onValueChange(value)
      }, [value])
  
  

     return (
          <div className="card flex justify-content-center">
               <span className="p-float-label">
                    <InputTextarea 
                         id="note" 
                         value={value} 
                         onChange={(e) => setValue(e.target.value)} 
                         rows={2}  
                         style={{height: '100%', width: '100%'}}
                    />
                    <label htmlFor="note">Notas</label>
               </span>
          </div>
     )
}
export default TextArea