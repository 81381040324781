import React, { useEffect, useState } from 'react'
import '../styles/GenericTableResult.css'
import { GetMaquinaryResult } from '../request';
import { Filters, MaquinaryResult } from '../types';
import { GetPastMonthOfPastYear } from '../helpers';

type Props = {
     title: string;
     data: any;
     filters: Filters
}



const ResultTableMaquinary: React.FC<Props> = ({title, data, filters}) => {
     
     const [maquinaryResults, setMaquinaryResults] = useState<MaquinaryResult[]>();

     useEffect(() => {
          initialize();
     },[])

     const initialize = async () => {
          const result = await GetMaquinaryResult(filters);
          // console.log(result);
          setMaquinaryResults(result)
     }

     const pastMonth = GetPastMonthOfPastYear()

     return (
          <section className="grid-item">
               <span id='tableResultTitle'>{title}</span>
               <table id='tableResultGCon'>
                    <thead>
                         <tr>
                              <th rowSpan={2} >LINEA</th>
                              <th colSpan={4} >Mensual</th>
                              <th colSpan={3} >Anual</th>
                         </tr>
                         <tr>
                              <th>{pastMonth}</th>
                              <th>Ppto.</th>
                              <th>Avance</th>
                              <th>%</th>
                              <th>Ppto.</th>
                              <th>Avance</th>
                              <th>%</th>
                         </tr>
                    </thead>
                    <tbody>
                    
                    {maquinaryResults && maquinaryResults.map((item: MaquinaryResult, index: any) => (
                         <tr key={index} style={{padding: 0, height: 2}}>
                              <th>{item.line}</th>
                              <td>{item.pastMonthQuantity}</td>
                              <td>{item.monthlyBudget}</td>
                              <td>{item.currentMonthProgress}</td>
                              <td>{item.currentMonthPercentage}</td>
                              <td>{item.annualBudget}</td>
                              <td>{item.currentYearProgress}</td>
                              <td>{item.currentYearPercentage}</td>
                         </tr>
                    ))}
                    </tbody>
               </table>
          </section>
     )
}

export default ResultTableMaquinary