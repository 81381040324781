/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Dropdown } from 'primereact'
import { DEPARTMENTS, DEPARTMENTS_WITH_ALL } from '../constants/constants.ts'

type Props = {
    onValueChange: (param: number [] | null)=>void,
    withAll?: boolean;
}

const DropdownDepartmentSingle:React.FC<Props> = ({ onValueChange, withAll }) => {

    const [departmentSelected, setDepartmentSelect] = useState<number [] | null>(null)

    useEffect(() => {
        onValueChange(departmentSelected)
    }, [departmentSelected])


    return (
        <span className="p-float-label panel-element">
            <Dropdown
                inputId="dd-sucursal"
                value={departmentSelected && departmentSelected[0]}
                options={withAll ? DEPARTMENTS_WITH_ALL: DEPARTMENTS}
                onChange={(e: any) => setDepartmentSelect([e.value])}
                placeholder="Departamento"
                className="dropdown"
                scrollHeight='500px'
            />
            <label htmlFor="dd-sucursal">Departamento</label>
        </span>
    )
}

export default DropdownDepartmentSingle

