import axios from 'axios'

const SERVER = "/backend/seguimientoclientes"
const GET_QUOTES = "/getListQuotes";
const GET_QUOTES_PER_MONTH = "/getQuotesPerMonth";
const GET_QUOTES_PER_YEAR = "/getQuotesPerYear";
const UPDATE_QUOTES = "/updateQuote";
const GET_STATUSES = "/getQuotesStatuses"

//const GET_SELLERS_REF = "/getSellersRef";

let statuses;

//export const GetSellersRef = async () => {
//    try {
//        const response = await axios.get(SERVER + GET_SELLERS_REF); 
//        console.log(response.data) ;
//    } catch (error) {
//        console.error('Error al obtener los nombres de los vendedores:', error);
//        throw error;
//    }
//};

export const GetQuotes = async (filters) => {
    const { branch, seller, status } = filters
    const response = await axios.get(SERVER + GET_QUOTES, {
        params: {
            branch,
            seller,
            year: 1900,
            month: -1,
            status
        },
    })
        .catch(error => console.error('Error al obtener q:', error))
    return response.data;
};

export const GetQuotesPerMonth = async (filters) => {
    const { branch, seller, status } = filters
    const response = await axios.get(SERVER + GET_QUOTES_PER_MONTH, {
        params: {
            branch,
            seller,
            status
        }
    })
        .catch(error => console.error('Error al obtener qpm:', error))
    const dataExtracted = response.data[0] ?
        JSON.parse(response.data[0].quotes_per_month) :
        null
    console.log(dataExtracted)
    return handleDataToQPM(dataExtracted)
};

export const GetQuotesPerYear = async (filters) => {
    const { branch, seller, status } = filters
    const response = await axios.get(SERVER + GET_QUOTES_PER_YEAR, {
        params: {
            branch,
            seller,
            status
        }
    })
        .catch(error => console.error('Error al obtener cotizaciones:', error))

    const dataExtracted = response.data[0] ?
        JSON.parse(response.data[0].quotes_per_year) :
        null
    return extractStatusesAndHandleQPY(dataExtracted);
};


export const GetStatues = async () => {
    const response = await axios.get(SERVER + GET_STATUSES)
        .catch(error => console.error('Error al obtener los estatus:', error))

    return response.data;
}


export const UpdateQuotes = async (data) => {
    const response = await axios.post(SERVER + UPDATE_QUOTES, {
        kQuote: data.quote,
        kStatus: data.status,
        note: data.note,
    })
        .catch(error => console.error('Error al obtener cotizaciones:', error))

    return response.data;
};


const extractStatusesAndHandleQPY = (quotesPerYear) => {
    if (!quotesPerYear) {
        return null;
    }

    statuses = quotesPerYear.map(element => {
        return ({ name: element.Descripcion, color: element.Color })
    })

    const labels = quotesPerYear.map(element => element.Descripcion);
    const datasets = [{
        data: quotesPerYear.map(element => element.Cantidad),
        backgroundColor: statuses.map(element => element.color),
    }]

    return { labels, datasets };
};

const handleDataToQPM = (qpm) => {
    if (!qpm) {
        return null;
    }

    const labels = qpm.map(element => element.Month);

    const datasets = statuses.map(status => ({
        label: status.name,
        borderColor: 'white',
        backgroundColor: status.color,
        data: qpm.map(element => element[status.name]),
    }));

    return { labels, datasets };
};
