import React,{FC} from 'react'
import { Chart } from 'react-google-charts';

type Props = {
     title: string;
     data: any;
 }
 
const AccumulateResultsState:FC<Props> = ({title, data}) => {
     return (
          <section className="grid-item" style={{width: '7cm'}}>
          <span id='tableResultTitle'>{title}</span>
          <Chart
              width={'100%'}
              height={'230px'}
              chartType="ComboChart"
              loader={<div>Loading Chart</div>}
              data={data}
              options={{
               // title: title,
                  chartArea: { width: '100%', height: '82%' },
                  vAxis: {
                      ticks: [],
                      gridlines: {
                         color: 'transparent' 
                     }
                  },
                  hAxis: {
                      titleTextStyle: { fontSize: 5 },
                      textStyle: {
                         fontSize: 7,
                         fontWeight: 'bold' 
                     },
                  },
                  isStacked: true,
                  seriesType: 'bars',
                  series: {
                    0: { type: 'bars', color: '#367c2b' }, 
                    1: { type: 'bars', color: '#a2c68c' },
                    2: { type: 'line', lineDashStyle: [4, 4], color: 'red', pointSize: 1,lineWidth: 1  },
                    3: { type: 'line', lineDashStyle: [1, 3], color: 'blue', pointSize: 2,lineWidth: 1  }, 
                  },
                  legend: {
                      position: 'bottom',
                      alignment: 'center',
                      textStyle: {
                          fontSize: 7,
                          fontWeight: 'bold'
                      }
                  },
                  annotations: {
                    textStyle: {
                        fontSize: 6,
                        format: 'decimal',
                    //     color: 'black',
                        auraColor:'none',
                        opacity: 0.8,
                    },
                    stem: {
                        length: 15,
                    },
                    // alwaysOutside: true
                }
              }}
          />
      </section>
     )
}

export default AccumulateResultsState