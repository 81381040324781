import React, { useEffect, useState } from 'react'
import Map from './Components/Map.tsx'
import Filters  from './Components/Filters.tsx'
import './styles.css'
import { TreeModuleFilters, TreePin } from './types.ts'
import { Button,Dialog } from 'primereact'
import AddRecord from './Components/NuevoRegistro/AddRecord.tsx'
import { validateFilters } from '../../../helpers/index.ts'
import { RequestTreesPinData, RequestTreesToPlant } from './requests/Trees.ts'
import Separator from './Components/Separator.tsx'

const DEFAULT_FILTERS: TreeModuleFilters = {
     onlyAditionals: -1,
     from: null,
     to: null, 
     employee: null,
     department: null,
     branch: -1
}

const TreeAnalitics = () => {

     const [filters, setFilters] = useState<TreeModuleFilters>(DEFAULT_FILTERS)
     const [showNewRecord, setShowNewRecord] = useState<boolean>(false);
     const [treePinList, setTreePinList] = useState<TreePin[]>([]);
     const [treesToPlant, setTreesToPlant] = useState<number>(0);
     const [rerender, setRerender] = useState<boolean>(false);

     useEffect(()=>{
          document.title = 'Árboles Plantados'
     },[])

     useEffect(()=>{
          validateFilters(filters, initialize);
     },[filters])

     const initialize = async () => {
          try{
               const treesToPlant = await RequestTreesToPlant(filters)
               setTreesToPlant(treesToPlant)

               const pinList = await RequestTreesPinData(filters);
               setTreePinList(pinList) 


          }
          catch(error){
               console.error(error)
          }
     }

     const endTreePlantation = () => {
          setShowNewRecord(false);
          setRerender(!rerender);
          initialize();
     }
     
     return (
          <>
          <main className='mainContainer'>
               <section className='filterAndSummary'>
                    <Filters
                         filters={filters}
                         setFilters={setFilters}
                    />

                    <Separator/>

                    <Button 
                         label="Nueva Plantación"
                         size='medium'
                         severity='warning'
                         icon="pi pi-plus"
                         style={{
                              display: 'flex',
                              gap: '0.5rem',
                              color: 'black',
                              fontWeight: 'bold',
                              width: '100%',
                              justifyContent: 'center'
                         }}
                         onClick={()=>{setShowNewRecord(true)}}
                         />
               </section>

               <Map 
                    filters={filters}
                    treePinList={treePinList}
                    branch={filters.branch}
                    rerender={rerender}
                    toPlant={treesToPlant}
               />

               <Dialog 
                    visible={showNewRecord} 
                    onHide={endTreePlantation} 
                    header={'Nuevo Registro de Arboles Plantados'}
                    headerStyle={{margin:0,padding:'10px 10px 0 1.5rem'}} 
                    position='top'
                    >
                    <AddRecord
                         finishRecord={endTreePlantation}
                         />
               </Dialog>

          </main>
          <div className="text-right text-gray-400 font-italic pt-1">~ Diseñado e implementado por Valentin Soto</div>
          </>
          
     )
}
export default TreeAnalitics