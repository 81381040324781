import React, { useEffect } from 'react'
import { Button } from 'primereact/button';
import { ARBOL, DIRECTION, FOTO, FOTO2, FOTO3, IMAGE } from '../../../../assets';
import { TreePin } from '../types';

type Props = {
     pinData: TreePin
}
const PinDetail: React.FC<Props> = ({ pinData }) => {


     // const imgPreview = !images ? images[0] : ARBOL
     const imgPreview = FOTO
     const variusTrees = pinData.amountPerLocation > 1
     return (
          <div className='flex justify-between gap-2' >
               <div className="fade-container">
                    <div className="fade-overlay" />
                    <img src={ARBOL} className="fade-image" />
                    <img src={IMAGE} className="overlay-image" id='btnSeeImagesFetched' />
               </div>
               <div className='pindetailcontentbody'>
                    <span className='text-justify'>{pinData.customerConcept}</span>
                    
                    <section className='flex flex-column gap-1'>
                         <span>{pinData.note && pinData.note}</span>
                         <span>{pinData.amountPerLocation} {variusTrees ? 'Árboles en esta ubicación.': 'Árbol en esta ubicación.'} </span>
                         <span style={{alignSelf: 'self-start'}}>~ {pinData.employee}</span>
                    </section>
                    <section id='btnGetDirs2' className='pin-direction-section'
                    >   
                         <span>Ver como llegar</span>
                         <img 
                              className='btn-get-directions ' 
                              src={DIRECTION} 
                         />
                              
                    </section>
               </div>
          </div>
     )
}

export default PinDetail