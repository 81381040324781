import axios from 'axios';
import { DARKCOLORS } from '../../../../constants/constants.ts';
import { SummaryMockData } from '../mocks.js';

const SOURCE = '/backend/budgetadvancement';
const BUDGET_ADVANCEMENT = '/rstBA';
const DEPARTMENTS_SELLERS = '/rqstDS';
const SUMMARY = '/gtsmmrydt';


export const GetMainData = async (filters) => {
    try {
        let parsedData = {};
        const { branch, sellers, month, isAccumulated, departments } = filters;

        // console.log(sellers);

        const response = await axios.get(`${SOURCE}${BUDGET_ADVANCEMENT}`, {
            params: {
                branch,
                seller: sellers.join(','),
                month,
                isAccumulated,
                line: departments.join(',')
            }// TAN FACL COMO HACER ESTO
        });

        if (!response) return {}

        for (const key in response.data) {
            parsedData[key] = JSON.parse(response.data[key]);
        }

        parsedData = {
            ...parsedData,
            GROSSPROFIT_LASTYEAR: handleGPDC(parsedData.GROSSPROFIT_LASTYEAR),
            MARGIN_LASTYEAR: handleMPY(parsedData.MARGIN_LASTYEAR),
            BUDGET_ADVANCEMENT_CURRENT_YEAR: handleBA(parsedData.BUDGET_ADVANCEMENT_CURRENT_YEAR)
        }
        return parsedData;

    } catch (error) {
        return {};
    }
};




const handleBA = (ba) => {
    if (!ba) {
        return null;
    }
    const labels = [...new Set(ba.map(element => element.MONTH))];
    const datasets = [
        {
            label: 'Presupuesto',
            data: ba.map((item) => item.BUDGET),
            fill: false,
            borderColor: 'darkblue',

            backgroundColor: 'darkorange',

        },
        {
            label: 'Avance',
            data: ba.map((item) => item.ADVANCEMENT),
            fill: false,
            borderColor: 'darkgreen',
            backgroundColor: 'darkgreen',
        }
    ]
    return { labels, datasets };
};
const handleMPY = (mpy) => {
    if (!mpy) {
        return null;
    }
    const labels = [...new Set(mpy.map(element => element.MONTH))];
    const sellers = [...new Set(mpy.map(element => element.SELLER))];

    const datasets = sellers.map((seller, index) => {

        const data = [];
        mpy.forEach(element => {
            if (element.SELLER === seller) {
                data.push(element.PERCENT)
            }
        });
        return ({
            label: seller,
            borderColor: DARKCOLORS[index],
            backgroundColor: DARKCOLORS[index],
            pointStyle: 'circle',
            pointRadius: 6,
            pointHoverRadius: 15,
            tension: 0.3,
            borderWidth: 1,
            data
        })

    });
    return { labels, datasets };
};
const handleGPDC = (gpdc) => {
    if (!gpdc) {
        return null;
    }

    const labels = [...new Set(gpdc.map(element => element.MONTH))];
    const sellers = [...new Set(gpdc.map(element => element.SELLER))];

    const datasets = sellers.map((seller, index) => {

        const data = [];
        gpdc.forEach(element => {
            if (element.SELLER === seller) {
                data.push(element.AMOUNT)
            }
        });
        return ({
            label: seller,
            borderColor: DARKCOLORS[index],
            backgroundColor: DARKCOLORS[index],
            pointStyle: 'circle',
            pointRadius: 6,
            pointHoverRadius: 15,
            tension: 0.2,
            borderWidth: 1,
            data
        })

    });

    return { labels, datasets };
    // return null
};

export const DepartmentsAndSellers = async (branch) => {
    try {

        let parsedData = {};
        const response = await axios.get(`${SOURCE}${DEPARTMENTS_SELLERS}`, {
            params: {
                branch
            }
        });
        if (!response) return {}

        for (const key in response.data) {
            parsedData[key] = JSON.parse(response.data[key]);
        }
        parsedData = {
            ...parsedData,
            SELLERS: handleDeps(parsedData)
        }

        return parsedData;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

const handleDeps = (das) => {
    if (!das) {
        return null;
    }

    const sellers = das.SELLERS;
    //deps names
    const labels = [...new Set(sellers.map(element => element.department))];

    const data = labels.map(label => {
        const items = [];
        sellers.forEach(element => {
            if (element.department === label) {
                items.push(element)
            }
        });
        return {
            label,
            code: label,
            items
        }

    })

    return data;
    // return null
};


export const GetSummaryData = async (filters) => {
    const { branch, sellers, month, isAccumulated, departments } = filters;
    try {
         const response = await axios.get(`${SOURCE}${SUMMARY}`,{
            params: {
                branch,
                seller: sellers.join(','),
                month,
                isAccumulated,
                line: departments.join(',')
            }
         })

         if (!response) throw new Error('Sin Datos')
         if (response.data == 'EMPTY') throw new Error('Sin Datos')

         return response.data
    } catch (error) {
         return SummaryMockData
    }
}