import React from 'react'
import MesualResultsStatues from './MesualResultsStatues'
import AccumulateResultsState from './AccumulateResultsState';

const FinancialIndicators = () => {
     const dataEstadoMensual = [
          { Concepto: 'Venta', Presupuesto: 25113, Avance: 18611, Porcentaje: '74%' },
          { Concepto: 'Costo', Presupuesto: 20261, Avance: 13994, Porcentaje: '69%' },
          { Concepto: 'Utilidad Bruta', Presupuesto: 4851, Avance: 4617, Porcentaje: '95%' },
          { Concepto: 'Gastos', Presupuesto: 2993, Avance: 2962, Porcentaje: '99%' },
          { Concepto: 'Utilidad de op.', Presupuesto: 1858, Avance: 1655, Porcentaje: '89%' },
          { Concepto: 'Gastos Financ.', Presupuesto: 30, Avance: 25, Porcentaje: '82%' },
          { Concepto: 'Otros gastos', Presupuesto: 0, Avance: 1, Porcentaje: '0%' },
          { Concepto: 'Utilidad A de Imp.', Presupuesto: 1828, Avance: 1630, Porcentaje: '89%' },
          { Concepto: 'Impuesto', Presupuesto: 728, Avance: 652, Porcentaje: '90%' },
          { Concepto: 'Utilidad Neta', Presupuesto: 1100, Avance: 978, Porcentaje: '89%' }
        ];
     //    const datosCarteraChart = [
     //      ['Año', 'Utilidad Neta', { role: 'annotation' }, 'Venta', { role: 'annotation' }, 'Ppto Venta',{ role: 'annotation' }, 'Ppto Utilidad',{ role: 'annotation' },],
     //      ['2023', 26121, ' Utilidad neta: 26,121', 365750, 'Venta: 365,750', 272481,'Ppto. Venta: 272,481', 9100,'Ppto.Utilidad: 9,100'],
     //      ['2024', 21610, 'Utilidad neta: 21,610', 333320, 'Venta: 333,320', 249617,'Ppto. Venta: 249,617', 6733,'Ppto.Utilidad: 6,733'],
     //  ];
     const datosCarteraChart = [
          ['Año', 'Utilidad Neta', { role: 'annotation' }, 'Venta', { role: 'annotation' }, 'Ppto Venta', { role: 'annotation' }, 'Ppto Utilidad', { role: 'annotation' }],
          ['2023', 11111, 'Utilidad neta: 11,111', 22222, 'Venta: 22,222', 33333, 'Ppto. Venta: 33,333', 44444, 'Ppto.Utilidad: 44,444'],
          ['2024', 55555, 'Utilidad neta: 55,555', 66666, 'Venta: 66,666', 77777, 'Ppto. Venta: 77,777', 88888, 'Ppto.Utilidad: 88,888'],
      ];
      
      
     return (
          <section className="grid-item">
               <span id='tableResultTitle'>Indicadores financieros</span>  
               <div id='financialIndicators'> 
                    <div>
                         <MesualResultsStatues  data={dataEstadoMensual}/>
                    </div>
                    <div>
                         <AccumulateResultsState title='Estado de Resultados Acumulado'
                              data={datosCarteraChart}
                         />
                    </div>
               </div>
          </section>
     )
}

export default FinancialIndicators